import { useState, useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import { useForm, FormProvider } from "react-hook-form";
import { Paper } from "@mui/material";
import { useRouter } from "../../utils/router";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useMsal } from "@azure/msal-react";

import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';


import PreviewIcon from '@mui/icons-material/Preview';

import {
  GridOverlay,
  useGridApiRef,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';

import { getRequests } from "../../utils/db";
import CircularProgress from '@mui/material/CircularProgress';


const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme) => ({
    actions: {
      color: theme.palette.text.secondary,
    },
    textPrimary: {
      color: theme.palette.text.primary,
    },
  }),
  { defaultTheme },
);

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
        <CircularProgress />
    </GridOverlay>
  );
}


export default function ViewApprovalListPage() {
  const { instance, accounts, inProgress } = useMsal();
  const router = useRouter();
  const apiRef = useGridApiRef();
  const classes = useStyles();
  const masterForm = useForm();


  const [RequestListData, setRequestListData] = useState([]);
  const [loadingRequestData, setLoadingRequestData] = useState(true);

  useEffect(() => {
    async function getRequestData(){
      const getRequestData = await getRequests(instance,accounts);
      console.log("PARSED DATA ", getRequestData);

      const parsedRequestData = [...getRequestData];
      setRequestListData(parsedRequestData);

      setLoadingRequestData(false);
    }
    getRequestData();
  }, []);

  const handleViewClick = (id) => (event) => {
    event.stopPropagation();
    const rowToView = apiRef.current.getRow(id);
    router.push('/request/update/Price_Approval/' + id);
  }

  const handleDeleteClick = (id) => (event) => {
    event.stopPropagation();
  }


  const RequestColumns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: classes.actions,
      getActions: ({ id }) => {
        const isInEditMode = apiRef.current.getRowMode(id) === 'edit';

        return [
          <GridActionsCellItem
            icon={<PreviewIcon />}
            label="View Request"
            className={classes.textPrimary}
            onClick={handleViewClick(id)}
            color="inherit"
          />
        ];
      },
    },
    { field: 'RequestID', headerName: 'ID', width: 75, editable: false },
    { field: 'Requester', headerName: 'Requester', width: 180, editable: false },
    { field: 'RequestType', headerName: 'RequestType', width: 180, editable: false },
    { field: 'RequestStatus', headerName: 'RequestStatus', width: 250, editable: false },
    { field: 'RequestTitle', headerName: 'RequestTitle', width: 180, editable: false },
    { field: 'RequestRegion', headerName: 'RequestRegion', width: 180, editable: false },
    { field: 'RequestFunction', headerName: 'RequestFunction', width: 180, editable: false },
    { field: 'RequestLocation', headerName: 'RequestLocation', width: 180, editable: false },
    { field: 'RequestLastUpdateDate', headerName: 'RequestLastUpdateDate', width: 180, editable: false },
    { field: 'RequestSubmissionDate', headerName: 'RequestSubmissionDate', width: 180, editable: false },
  ];

  return (
    <>
      <AuthenticatedTemplate>
        <Paper
          elevation={4}
          display="flex"

          sx={{ borderRadius: 2, width: 1, height: 1, flexDirection: "column" }}

        >
          <Typography
            variant="h4"
            component="h4"
            pt={1}
            pb={1}
            align="center"
            alignSelf={"center"}
            sx={{
              fontWeight: 500,
              bgcolor: "info.main",
              color: grey[50],
              borderRadius: "5px 5px 0px 0px",
              mb: 1,
            }}
          >
            All Requests
          </Typography>
          <Box height={1} flexGrow={1} >
             <DataGridPro         components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        loading={loadingRequestData} rows={RequestListData} columns={RequestColumns} getRowId={(row) => row.RequestID}  apiRef={apiRef}  />
          </Box>
        </Paper>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Typography
          variant="h3"
          component="h3"
          flexGrow={2}
          align="center"
          alignSelf={"center"}
          sx={{ fontWeight: 600 }}
        >
          You are NOT signed in.
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
}
