import { useMemo } from "react";
import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";

import { apiRequest } from "./util";

// React Query client
const client = new QueryClient();


export async function createApprovalRequest(instance, accounts, requestType, requestData){
  const createRow = await apiRequest(
    instance,
    accounts,
    ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
    `createApprovalRequest?&RequestType=${requestType}`,
    "POST",
    {
      ...requestData,
    }
  );

  return createRow
}

export async function sendEmail(instance, accounts, requestID, requestData){
  const createRow = await apiRequest(
    instance,
    accounts,
    ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
    `SendEmail?&requestID=${requestID}`,
    "POST",
    {
      ...requestData,
    }
  );

  return createRow
}


export async function updateApprovalRequest(instance, accounts, RequestID, requestData){
  const createRow = await apiRequest(
    instance,
    accounts,
    ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
    `updateApprovalRequest?&RequestID=${RequestID}`,
    "POST",
    {
      ...requestData,
    }
  );

  return createRow
}


const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
export function useGetApprovalRequest(instance, accounts, RequestID) {
  console.log("Ran Retrieve Approval Request");
  return useQuery(
    ["ApprovalRequest", {RequestID} ],
    () =>
      apiRequest(
        instance,
        accounts,
        ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
        `getAppprovalRequest?RequestID=${RequestID}`
      ),
      {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      }
  );
}

export function useWarmUp(instance, accounts) {
  console.log("Ran Retrieve Approval Request");
  return useQuery(
    ["WarmUp" ],
    () =>
      apiRequest(
        instance,
        accounts,
        ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
        `warmUpTrigger`
      ),
      {
        refetchInterval: 240000
      }
  );
}


export function useGetApprovalRequestApprovers(instance, accounts, RequestID) {
  console.log("Ran Retrieve Approval Request");
  return useQuery(
    ["ApprovalRequestApprovers", {RequestID} ],
    () =>
      apiRequest(
        instance,
        accounts,
        ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
        `getApprovalRequestApprovers?RequestID=${RequestID}`
      ),
      {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      }
  );
}


export async function getApprovers(instance, accounts, RequestType, RequestFilters) {
  const getApprover = await apiRequest(
        instance,
        accounts,
        ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
        `getApprovers?RequestType=${RequestType}`,
        "POST",
        {
          ...RequestFilters
        }
  );

  return getApprover
}

export async function getRequests(instance, accounts,) {
  const listRequests = await apiRequest(
        instance,
        accounts,
        ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
        `ListRequests`,
  );

  return listRequests
}

export async function setApprovers(instance, accounts, ApproverList, deleteApprovals = false ) {
  const setApprovers = await apiRequest(
        instance,
        accounts,
        ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
        `setApprovers?DeleteApprovals=${deleteApprovals}`,
        "POST",
        {
          ...ApproverList
        }
  );

  return setApprovers
}

export function useGetCustomers(instance, accounts) {
  return useQuery(
    ["Customers"],
    () =>
      apiRequest(
        instance,
        accounts,
        ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
        `getCustomers`
      ),
      {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      }
  );
}

export function useGetPriceList(instance, accounts, PriceList, Customer = "") {
  return useQuery(
    ["PriceList", {PriceList}, {Customer}],
    () =>
      apiRequest(
        instance,
        accounts,
        ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
        `getPriceListData?PriceList=${PriceList}&Customer=${Customer}`
      )
  );
}

export async function getStandardCost(instance, accounts, sku, zone) {
  const getStandardCost = await apiRequest(
        instance,
        accounts,
        ["api://e49af16f-58e1-46ea-982b-47b084eab98a/app.access.approval.request.apis"],
        `getStandardCost?sku=${sku}&zone=${zone}`,
  );

  return getStandardCost
}
// export function useGetCompanyCodes(instance, accounts, owner) {
//   console.log("Attmepted Company Codes");
//   return useQuery(
//     ["codes", { owner }],
//     () =>
//       apiRequest(
//         instance,
//         accounts,
//         ["api://a5239b6b-7d1d-4572-8ef2-3698541be563/app.access.hr.api"],
//         `getCompanyCodes?owner=${owner}`
//       )
//   );
// }

// export function useGetCurrencies(instance, accounts, year, month) {
//   console.log("Attmepted Company Currencies");

//   return useQuery(
//     ["code", { year }],
//     () =>
//       apiRequest(
//         instance,
//         accounts,
//         ["api://a5239b6b-7d1d-4572-8ef2-3698541be563/app.access.hr.api"],
//         `getCurrencyExchangeRates?year=${year}&month=${month}`
//       ),
//     { select: false }
//   );
// }


// export function useUpdateRow(instance, accounts, reportDate, companyCode){

//   return useMutation( (data) => 
//     {
//       console.log(data);
//       return apiRequest(
//         instance,
//         accounts,
//         ["api://a5239b6b-7d1d-4572-8ef2-3698541be563/app.access.hr.api"],
//         `UpdateReportRow?companycode=${companyCode}&rowid=${data['app_monthlyreportid']}`,
//         "POST",
//         {
//           ...data,
//         });
//   },
//     {
//       onSuccess: () => { 
//         return client.invalidateQueries(["userReports",{ reportDate },{ companyCode }])
//       }
//     }
//   );

// }

// swa http://localhost:3000/ --api-location  http://localhost:7071


// // export async function updateRow(
// //   instance,
// //   accounts,
// //   reportDate,
// //   companyCode,
// //   data
// // ) {
// //   const updateRow = await apiRequest(
// //     instance,
// //     accounts,
// //     ["api://a5239b6b-7d1d-4572-8ef2-3698541be563/app.access.hr.api"],
// //     `UpdateReportRow?companycode=${companyCode}&rowid=${data['app_monthlyreportid']}`,
// //     "POST",
// //     {
// //       ...data,
// //     }
// //   );
 
// //   await client.invalidateQueries([
// //     "userReports",
// //     { reportDate },
// //     { companyCode },
// //   ]);

// //   return updateRow;
// // }

// export async function CopyReport(
//   instance,
//   accounts,
//   reportDate,
//   companyCode,
//   newReportDate,
//   data
// ) {
//   const updateRow = await apiRequest(
//     instance,
//     accounts,
//     ["api://a5239b6b-7d1d-4572-8ef2-3698541be563/app.access.hr.api"],
//     `CopyReport?&reportdate=${reportDate}&companycode=${companyCode}&newreportdate=${newReportDate}`,
//     "POST",
//     {
//       ...data,
//     }
//   );

//   console.log( updateRow)

//   return updateRow;
// }

// export async function createRow(
//   instance,
//   accounts,
//   reportDate,
//   companyCode,
//   data
// ) {
//   const createRow = await apiRequest(
//     instance,
//     accounts,
//     ["api://a5239b6b-7d1d-4572-8ef2-3698541be563/app.access.hr.api"],
//     `createRow?&reportdate=${reportDate}&companycode=${companyCode}`,
//     "POST",
//     {
//       ...data,
//     }
//   );

//   await client.invalidateQueries([
//     "userReports",
//     { reportDate },
//     { companyCode },
//   ]);

//   return createRow;
// }


// export function useCreateRow(instance, accounts, reportDate, companyCode){

//   return useMutation( (data) => 
//     {
//       console.log(data);
//       return apiRequest(
//         instance,
//         accounts,
//         ["api://a5239b6b-7d1d-4572-8ef2-3698541be563/app.access.hr.api"],
//         `createRow?&reportdate=${reportDate}&companycode=${companyCode}`,
//         "POST",
//         {
//           ...data,
//         }
//       );
//   },
//     {
//       onSuccess: () => { 
//         return client.invalidateQueries(["userReports",{ reportDate },{ companyCode }])
//       }
//     }
//   );
// }


// export function useGetReportV2(instance, accounts, reportDate, companyCode) {
//   console.log( "enabled: ", !!companyCode);
//   return useQuery(["userReports", { reportDate }, { companyCode }], () =>
//     apiRequest(
//       instance,
//       accounts,
//       ["api://a5239b6b-7d1d-4572-8ef2-3698541be563/app.access.hr.api"],
//       `getReportData?&reportdate=${reportDate}&companycode=${companyCode}`
//     ),
//     {
//       enabled: !!companyCode,
//     }
//   );
// }

// export function useGetCompanyCodes(instance, accounts, roles, owner) {
//   const companyCodes = useQuery(
//     ["codes", { owner }],
//     () =>
//       apiRequest(instance, accounts, ["User.Read"], `company?owner=${owner}`),
//     { enabled: !!owner }
//   );
//   console.log(roles, companyCodes);

//   return {
//     ...companyCodes,
//     data: useMemo(
//       (data) => {
//         if (companyCodes.data !== undefined) {
//           return companyCodes.data.filter((item) => roles.includes(item.Code));
//         }
//         return companyCodes.data;
//       },
//       [companyCodes.data, roles]
//     ),
//   };
// }

// "@material-ui/core": "4.12.3",
// "@material-ui/icons": "4.11.2",
// "@material-ui/lab": "4.0.0-alpha.60",

// export function useReports(instance, accounts) {
//   return useQuery(["reports"], () =>
//     apiRequest(instance, accounts, ["User.Read"], `reports`)
//   );
// }

// export function useReport(instance, accounts) {
//   return useQuery(["reports"], () =>
//     apiRequest(instance, accounts, ["User.Read"], `reports`)
//   );
// }

// export function useGetReports(instance, accounts) {
//   return useQuery(["userReports"], () =>
//     apiRequest(instance, accounts, ["User.Read"], `reports`)
//   );
// }

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
