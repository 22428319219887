import * as React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import { useForm, FormProvider } from "react-hook-form";
import UpdatePriceApprovalFormV2 from "../../../components/price_approval_forms/update_price_approval/UpdatePriceApprovalFormV2";

import { Paper } from "@mui/material";
import { useRouter } from "../../../utils/router";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";

export default function UpdatePriceApprovalPage() {
  const masterForm = useForm();
  const router = useRouter();

  const RequestType = router.params["requestType"].replace("_", " ");
  const RequestID = parseInt(router.params["requestID"]);

  return (
    <>
      <AuthenticatedTemplate>
        <Paper
          elevation={4}
          display="flex"

          sx={{ borderRadius: 2, width: 1, height: 1, flexDirection: "column" }}

        >
          <Typography
            variant="h4"
            component="h4"
            pt={1}
            pb={1}
            align="center"
            alignSelf={"center"}
            sx={{
              fontWeight: 500,
              bgcolor: "info.main",
              color: grey[50],
              borderRadius: "5px 5px 0px 0px",
              mb: 1,
            }}
          >
            Viewing {router?.params["requestType"].replace("_", " ")} Request
          </Typography>
          <Box flexGrow={1} >
            {(() => {
              switch (router?.params["requestType"].replace("_", " ")) {
                case "Price Approval":
                  return <UpdatePriceApprovalFormV2  />
                case "Price ApprovalTest":
                    return <UpdatePriceApprovalFormV2  />
                default:
                  return "Not Found"
              }
            })()}
          </Box>
        </Paper>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Typography
          variant="h3"
          component="h3"
          flexGrow={2}
          align="center"
          alignSelf={"center"}
          sx={{ fontWeight: 600 }}
        >
          You are NOT signed in.
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
}
