import {  TextField } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import { Controller } from 'react-hook-form';

export const ControlledDatePicker = ({ renderInput, label, onChange: ignored, control, name, renderOption }) => {
  return (
    <Controller
    name={name}
    control={control}
      render={({ field: { onChange, value } }) => (
        <DatePicker
        onChange={onChange}
        value={value}
        label={label}
        renderInput={renderInput}
        />
      )}
    />
  );
}