//import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import { useState, useEffect, useRef } from "react";

import { useMsal } from "@azure/msal-react";
import { useRouter } from "../../../utils/router";

import { useForm, useWatch } from "react-hook-form";

import { Grid } from "@mui/material";
import { grey, blue } from "@mui/material/colors";
import { Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import VisibilityIcon from '@mui/icons-material/Visibility';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import {
  useGridApiRef,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';

import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { sendEmail, createApprovalRequest, getApprovers, useGetCustomers, getStandardCost, setApprovers, useGetApprovalRequest, useGetApprovalRequestApprovers, updateApprovalRequest, useGetPriceList } from "../../../utils/db";

import { calculateMargin } from "../utils/MarginCalculation";

import { ControlledAutocomplete } from "../../formComponents/ControlledAutocomplete"
import { ControlledAutocompleteV2 } from "../../formComponents/ControlledAutocompleteV2"

import { ControlledDatePicker } from "../../formComponents/ControlledDatePicker";
import { ControlledTextField } from "../../formComponents/ControlledTextField";
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { set } from "date-fns";

const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme) => ({
    actions: {
      color: theme.palette.text.secondary,
    },
    textPrimary: {
      color: theme.palette.text.primary,
    },
  }),
  { defaultTheme },
);

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key].includes(value));
}

//werwerwerwrw
function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3, marginLeft: -10 }}
          >
            <Typography variant="body2" style={{ padding: 6 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};


//{ "Sku": selectedSku, "Price": SkuDataList?.["Price(Each)"], "Zone": selectedCustomerZone }
async function setCosts(name, SkuData, setFormValue, instance, accounts) {
  if (SkuData["Sku"] !== "") {

    console.log(`*****Running Set Costs: ${name}*****`);

    if (SkuData["Price"] !== "" && SkuData["Price"] != 0 && name == "Sku") {
      const standardCost = await getStandardCost(instance, accounts, SkuData["Sku"], SkuData["Zone"]);

      console.log("1. Set Standard Cost: " + standardCost);
      setFormValue("StandardCost", standardCost);


      console.log("2. Set Current Margin: " + calculateMargin(SkuData["Price"], standardCost, SkuData["Price"]));
      setFormValue("CurrentMargin", calculateMargin(SkuData["Price"], standardCost, SkuData["Price"]));

    }

    if (SkuData["Price"] !== "" && SkuData["Price"] != 0 && name == "NewPrice") {
      const customerCost = await getStandardCost(instance, accounts, SkuData["Sku"], SkuData["Zone"]);

      console.log("1. Set Customer Cost: " + customerCost);

      setFormValue("CustomerCost", customerCost);

      console.log("1. Set Customer Zone: " + SkuData["Zone"]);


      console.log("2. Set Customer Margin: " + calculateMargin(SkuData["Price"], customerCost, SkuData["Price"]));
      setFormValue("NewMargin", calculateMargin(SkuData["Price"], customerCost, SkuData["Price"]));
    }
    console.log(`*****Finished Running Set Costs: ${name}*****`);
  }
}

export default function UpdatePriceApprovalForm() {
  const pageRouter = useRouter();
  const apiRef = useGridApiRef();
  const classes = useStyles();
  const { instance, accounts } = useMsal();

  const RequestType = pageRouter.params["requestType"].replace("_", " ");
  const RequestID = parseInt(pageRouter.params["requestID"]);

  const { status, data: rawCustomersList, error, isFetching } = useGetCustomers(instance, accounts);
  const { status: RawRequestDataStatus, data: RawRequestData, error: RawRequestDataErrors } = useGetApprovalRequest(instance, accounts, RequestID);
  const { status: RawRequestApproversStatus, data: RawRequestApprovers, error: RequestApproversErrors } = useGetApprovalRequestApprovers(instance, accounts, RequestID);

  const [CustomersData, setCustomersData] = useState([]);
  const [CustomerOptions, setCustomerOptions] = useState([]);
  const [SalesEmployeeOptions, setSalesEmployeeOptions] = useState([]);

  const [PriceListData, setPriceListData] = useState([]);

  const [SkuOptions, setSkuOptions] = useState([]);
  const [CategoryOptions, setCategoryOptions] = useState([]);

  const [rows, setrows] = useState([]);
  const [editingRow, setEditingRow] = useState(false);

  const [BlockUseEffect, setBlockUseEffect] = useState(false);

  const [BlockSubmit, setBlockSubmit] = useState(false);


  const [PriceDataLoading, setPriceDataLoading] = useState(false);

  const [isCurrentApprover, setIsCurrentApprover] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isViewer, setIsViewer] = useState(true);
  const [isRequester, setIsRequester] = useState(false);

  const approverRef = useRef("");

  const defaultFormValues = {
    Request_Title: "Price Approval Request: ",
    Request_Date: new Date(),
    Request_Requester: instance.getAllAccounts()[0].name,
    RequesterEmail: "",
    Request_Type: pageRouter.params["requestType"].replace("_", " "),
    Request_Status: "Drafting",
    Request_Region: "NACA",
    Request_Function: "",
    Request_Location: "",


    id: "",
    Customer: "",
    CustomerZone: "ZoneU-Parcel",
    SalesPerson: "",
    PriceList: "Distributor Price List",
    Currency: "US",
    BusinessType: "",
    EffectiveDate: new Date(),

    MonthlyQuantity: 0,
    TotalQuantity: 0,
    NumberOfMonths: "1",


    Category: "",
    SKU: "",
    Description: "",

    CurrentPrice: 0,
    CurrentMargin: 0,
    StandardCost: 0,
    CaseQty: 0,
    TotalValueCurrent: 0,
    CurrentPriceCase: 0,

    NewPrice: 0,
    NewPriceDiscount: 0,
    NewMargin: 0,
    CustomerCost: 0,
    TotalValueNewPrice: 0,
    TotalDiscountSku: 0,
    NewPriceCase: 0,

    TotalDiscountValue: 0,
    TotalDealValue: 0,
    TotalDiscountPercentage: 0,

    Approver: "",
    ApproverEmail: "",
    RequestApprover: "",
    RequestApproverName: "",

    Justification: "",
    Remarks: "",

    PriceListData: [],
    Categories: [],
    RawCustomerList: []
  }

  const { register, control, watch, handleSubmit, setValue, getValues, formState } = useForm(
    {
      mode: "onChange",
      defaultValues: defaultFormValues
    }
  );

  const selectedCustomer = useWatch({ control, name: 'Customer' });
  const RequestStatus = useWatch({ control, name: 'Request_Status' });
  const selectedPriceList = useWatch({ control, name: 'PriceList' });

  const { status: getPriceListStatus, data: rawPriceList, error: getPriceListError, isFetching: getPriceListIsFetching } = useGetPriceList(instance, accounts, selectedPriceList, selectedCustomer);

  useEffect(() => {
    if (RawRequestData && RawRequestData != null && RawRequestData != undefined) {
      const RequestDataParsed = JSON.parse(RawRequestData);
      if (instance.getAllAccounts()[0].username === RequestDataParsed["RequestJson"]["RequesterEmail"] && RequestDataParsed["RequestStatus"].includes("Drafting")) {
        setIsRequester(true);
        setIsViewer(false);
      }else if(instance.getAllAccounts()[0].username === RequestDataParsed["RequestJson"]["RequesterEmail"] && !RequestDataParsed["RequestStatus"].includes("Drafting")) {
        setIsRequester(true);
        setIsViewer(true);
      }

      setValue("id", RequestID);
      setValue("Request_Title", `Price Approval ${RequestID}`);
      setValue("Request_Date", RequestDataParsed["RequestSubmissionDate"]);
      setValue("Request_Requester", RequestDataParsed["Requester"]);
      setValue("Request_Type", RequestDataParsed["RequestType"]);
      setValue("Request_Status", RequestDataParsed["RequestStatus"]);
      setValue("Request_Region", RequestDataParsed["RequestRegion"]);
      setValue("Customer", RequestDataParsed["RequestJson"]["Customer"]);
      setValue("PriceList", RequestDataParsed["RequestJson"]["PriceList"]);
      setValue("Approver", RequestDataParsed["RequestJson"]["Approver"]);

      setValue("CustomerZone", RequestDataParsed["RequestJson"]["CustomerZone"]);
      setValue("SalesPerson", RequestDataParsed["RequestJson"]["SalesPerson"]);
      setValue("Currency", RequestDataParsed["RequestJson"]["Currency"]);
      setValue("BusinessType", RequestDataParsed["RequestJson"]["BusinessType"]);
      setValue("EffectiveDate", RequestDataParsed["RequestJson"]["EffectiveDate"]);
      setValue("ApproverEmail", RequestDataParsed["RequestJson"]["ApproverEmail"]);
      setValue("RequestApprover", RequestDataParsed["RequestJson"]["ApproverEmail"]);
      setValue("RequestApproverName", RequestDataParsed["RequestJson"]["Approver"]);

      setValue("RequesterEmail", RequestDataParsed["RequestJson"]["RequesterEmail"]); 

      setrows(RequestDataParsed["RequestJson"]["ApprovalRows"]);
    }
  }, [RawRequestData]);

  useEffect(() => {
    if (RawRequestApprovers && RawRequestApprovers != null && RawRequestApprovers != undefined && RawRequestData && RawRequestData != null && RawRequestData != undefined) {
      const RequestApproversParsed = JSON.parse(RawRequestApprovers);
      const RequestDataParsed = JSON.parse(RawRequestData);

      RequestApproversParsed.map(approver => {
        if (instance.getAllAccounts()[0].username === approver["Approver"] && approver["IsCurrentApprover"] === true && !RequestDataParsed["RequestStatus"].includes("Drafting")) {
          setIsCurrentApprover(true);
          setIsViewer(true);
          setIsApprover(true);
        }
        if (instance.getAllAccounts()[0].username === approver["Approver"] && approver["IsCurrentApprover"] === false && !RequestDataParsed["RequestStatus"].includes("Drafting")) {
          setIsViewer(true);
          setIsApprover(true);
        }
      });


    }
  }, [RawRequestData, RawRequestApprovers]);

  const selectedRequestApprover = useWatch({ control, name: 'RequestApprover' });
  const selectedSku = useWatch({ control, name: 'SKU' });

  useEffect(() => {
    if (rawCustomersList != undefined) {
      console.log("Parsing Customer List Data Started.");

      const CustomersListParsed = JSON.parse(rawCustomersList);
      setCustomersData([...CustomersListParsed]);

      const CustomerOptionsList = new Set(CustomersListParsed.map((data) => data["Customer"]));
      const SalesEmployeeOptionsList = new Set(CustomersListParsed.map((data) => data["SalesEmployeeName"]));

      setCustomerOptions([...CustomerOptionsList]);
      setSalesEmployeeOptions([...SalesEmployeeOptionsList]);
    }
  }, [rawCustomersList]);

  useEffect(() => {
    if (rawPriceList != undefined) {
      const PriceListParsed = JSON.parse(rawPriceList);
      setPriceListData([{ LabelCode: "", Category: "" }, ...PriceListParsed]);
    }
  }, [rawPriceList]);

  useEffect(() => {
    if (selectedRequestApprover != "" && selectedRequestApprover != undefined && selectedRequestApprover != null && selectedSku != "" && selectedSku != undefined && selectedSku != null) {

      async function updateData(RequestApprover) {
        console.log("RequestApprover", RequestApprover);

        const CategoryRequestFilters = {
          "RequestFilters": [
            { "SqlFunction": "none", "ComparisonOperator": "eq", "LogicalOperator": "none", "Key": "ApproverEmail", "Value": RequestApprover },
          ]
        }

        const ApproverCategoriesList = await getApprovers(instance, accounts, getValues("Request_Type"), CategoryRequestFilters);
        const CategoriesReturned = JSON.parse(ApproverCategoriesList).map(data => data["RequestSpecificJson"]["Category"]);

        let newSkuList = []

        newSkuList = [...PriceListData.filter(data => CategoriesReturned.includes(data["Category"]))];

        const newSkuOptions = [...new Set(newSkuList.map(data => data["LabelCode"]))];

        setSkuOptions(["", ...newSkuOptions]);

        setCategoryOptions(["", ...new Set(CategoriesReturned)]);
      }

      updateData(selectedRequestApprover);
    } else {

      const newSkuOptions = [...new Set(PriceListData.map(data => data["LabelCode"]))];
      const newCategoryOptions = [...new Set(PriceListData.map(data => data["Category"]))];

      setSkuOptions([...newSkuOptions]);
      setCategoryOptions([...newCategoryOptions]);
    }
  }, [PriceListData, selectedRequestApprover, selectedSku]);


  useEffect(() => {
    if (rows.length > 0) {
      const TotalRequestDealCurrent = rows.map(row => parseFloat(row["TotalValueCurrent"])).reduce((partialSum, a) => partialSum + a, 0);

      const TotalRequestDeal = rows.map(row => parseFloat(row["TotalValueNewPrice"])).reduce((partialSum, a) => partialSum + a, 0);

      const TotalRequestDiscount = rows.map(row => parseFloat(row["TotalDiscountSku"])).reduce((partialSum, a) => partialSum + a, 0);

      const TotalRequestDiscountPercentage = (100 * ((TotalRequestDealCurrent - TotalRequestDeal) / TotalRequestDealCurrent)).toFixed(2)

      setValue("TotalDealValue", TotalRequestDeal.toFixed(0));
      setValue("TotalDiscountValue", TotalRequestDiscount.toFixed(0));
      setValue("TotalDiscountPercentage", TotalRequestDiscountPercentage);
    }
  }, [rows]);

  useEffect(() => {
    if (getPriceListStatus == "loading") {
      setPriceDataLoading(true);
    }
    if (getPriceListStatus == "success") {
      setPriceDataLoading(false);
    }
  }, [getPriceListStatus]);

  async function updatedRowsOnCustomerChange() {
    if (rows.length >= 0) {
      async function getCost(row) {
        const customerCost = await getStandardCost(instance, accounts, row["SKU"], getValues("CustomerZone"));
        const maginCalculated = calculateMargin(row["NewPrice"], customerCost, row["NewPrice"]);

        let ApprovalBranch = "";
        if (maginCalculated > 0 && maginCalculated > row["CurrentMargin"] && row["CurrentPrice"] != 0) {
          ApprovalBranch = "Branch 1";
        } else if (maginCalculated > 0 && maginCalculated < row["CurrentMargin"] && row["CurrentPrice"] != 0) {
          ApprovalBranch = "Branch 2";
        } else {
          ApprovalBranch = "Branch 3";
        }
    


        // if (formInputs["NewMargin"] > formInputs["CurrentMargin"] && formInputs["CurrentPrice"] <= 0) {
        //   ApprovalBranch = "Branch 1";
        // } else if ((formInputs["NewMargin"] > 0 && formInputs["NewMargin"] < formInputs["CurrentMargin"]) && formInputs["CurrentPrice"] <= 0) {
        //   ApprovalBranch = "Branch 2";
        // } else if (formInputs["NewMargin"] < 0 || formInputs["CurrentPrice"] == 0) {
        //   ApprovalBranch = "Branch 3";
        // }

        let approversList = "";

        if (ApprovalBranch == "Branch 1") {
          approversList = "Automatically Approved";
        } else if (ApprovalBranch == "Branch 2") {
          approversList = row["Approver"];
        } else if (ApprovalBranch == "Branch 3") {
          approversList = `${row["Approver"]}, Liang Lin`;
        }else{
          approversList = "Error";
        }

        const skuInfo = PriceListData.find(element => element["LabelCode"] === row["SKU"]);
        const CasePrice = (parseInt(row["CaseQty"]) * parseFloat(skuInfo?.["Price(Each)"])).toFixed(2);
        console.log("case " + CasePrice);
        console.log("qty " + row["CaseQty"]);
        console.log("sku prc " + skuInfo?.["Price(Each)"]);

        let discount = 0;

        if (skuInfo?.["Price(Each)"] != 0) {

          const increase = row["NewPrice"] - skuInfo?.["Price(Each)"];
          const incPerc = parseFloat((increase / skuInfo?.["Price(Each)"]) * 100).toFixed(2);

          if (Math.sign(incPerc) == -1) {
            discount = Math.abs(incPerc);
          }
          if (Math.sign(incPerc) == 1) {
            discount = -(incPerc);
          }

          console.log("Increase:", incPerc);

        }

        const currentTotalValue = row["TotalQuantity"] * skuInfo?.["Price(Each)"]
        const newTotalDiscount = currentTotalValue - row["TotalValueNewPrice"]

        return await ({
          ...row,
          "CustomerCost": customerCost,
          "NewMargin": maginCalculated,
          "Branch": ApprovalBranch,
          "CurrentPrice": skuInfo?.["Price(Each)"],
          "CurrentPriceCase": CasePrice,
          "NewPriceDiscount": discount,
          "Approvers": approversList,
          "TotalValueCurrent": currentTotalValue.toFixed(0),
          "TotalDiscountSku": newTotalDiscount.toFixed(0)
        });
      }
      const updatedRows = await Promise.all(rows.map(row => (getCost(row))));
      setrows([...updatedRows]);
    }
  }

  if(RawRequestDataStatus == "loading" ){
    return (    
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={60}/>
      </Box>
    );
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleCellFocusOut = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => (event) => {
    event.stopPropagation();
    const rowToEdit = apiRef.current.getRow(id);

    setEditingRow(true);

    setValue("id", id);
    setValue("Justification", rowToEdit["Justification"]);
    setValue("Remarks", rowToEdit["Remarks"]);
    setValue("Branch", rowToEdit["Branch"]);
    setValue("Approver", rowToEdit["Approver"]);

    setValue("Category", rowToEdit["Category"]);
    setValue("SKU", rowToEdit["SKU"]);
    setValue("Description", rowToEdit["Description"]);
    setValue("CaseQty", rowToEdit["CaseQty"]);

    setValue("MonthlyQuantity", rowToEdit["MonthlyQuantity"]);
    setValue("NumberOfMonths", rowToEdit["NumberOfMonths"]);
    setValue("TotalQuantity", rowToEdit["TotalQuantity"]);

    setValue("CurrentPrice", rowToEdit["CurrentPrice"]);
    setValue("StandardCost", rowToEdit["StandardCost"]);
    setValue("CurrentMargin", rowToEdit["CurrentMargin"]);
    setValue("CurrentPriceCase", rowToEdit["CurrentPriceCase"]);
    setValue("TotalValueCurrent", rowToEdit["TotalValueCurrent"]);

    setValue("NewPrice", rowToEdit["NewPrice"]);
    setValue("NewMargin", rowToEdit["NewMargin"]);
    setValue("NewPriceDiscount", rowToEdit["NewPriceDiscount"]);
    setValue("NewPriceCase", rowToEdit["NewPriceCase"]);
    setValue("CustomerCost", rowToEdit["CustomerCost"]);
    setValue("TotalValueNewPrice", rowToEdit["TotalValueNewPrice"]);
    setValue("TotalDiscountSku", rowToEdit["TotalDiscountSku"]);

  };

  const handleSaveClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.commitRowChange(id);
    apiRef.current.setRowMode(id, 'view');

    const row = apiRef.current.getRow(id);
    apiRef.current.updateRows([{ ...row, isNew: false }]);
  };

  const handleDeleteClick = (id) => (event) => {
    event.stopPropagation();
    const newRows = rows.filter(row => row.id !== id);
    setrows(newRows);
  };

  const handleCancelClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.setRowMode(id, 'view');

    const row = apiRef.current.getRow(id);
    if (row.isNew) {
      apiRef.current.updateRows([{ id, _action: 'delete' }]);
    }
  };

  const PriceDatagridColumns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: classes.actions,
      getActions: ({ id }) => {
        const isInEditMode = apiRef.current.getRowMode(id) === 'edit';

        if (isViewer === false && isRequester === true) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className={classes.textPrimary}
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="View"
              className={classes.textPrimary}
              onClick={handleEditClick(id)}
              color="inherit"
            />,
          ];
        }
      },
    },
    { field: 'SKU', headerName: 'SKU', headerAlign: "center", width: 100, editable: false, align: "center" },
    { field: 'Approvers', headerName: 'Approvers', width: 200, headerAlign: "center", editable: false },
    {
      field: 'CurrentPrice', headerName: 'Current Price (Each)', headerAlign: "center", width: 100, editable: false, align: "center",
      valueFormatter: (params) => {
        return `$ ${params.value}`;
      }
    },
    {
      field: 'NewPriceDiscount', headerName: 'Discount % (Each)', headerAlign: "center", width: 100, editable: false, align: "center",
      valueFormatter: (params) => {
        return `${params.value} %`;
      }
    },
    {
      field: 'NewPrice', headerName: 'New Price (Each)', headerAlign: "center", width: 100, editable: false, align: "center",
      valueFormatter: (params) => {
        return `$ ${params.value}`;

      }
    },

    {
      field: 'TotalQuantity', headerName: 'Total Quantity (Each)', headerAlign: "center", width: 100, editable: false, align: "center",
      valueFormatter: (params) => {
        const nf = new Intl.NumberFormat('en-US');
        const value = parseInt(params.value);
        return `${nf.format(value)}`;
      }
    },
    {
      field: 'TotalValueNewPrice', headerName: 'Total Value', headerAlign: "center", width: 100, editable: false, align: "center",
      valueFormatter: (params) => {
        const nf = new Intl.NumberFormat('en-US');
        const value = parseInt(params.value);
        return `$ ${nf.format(value)}`;
      }
    },
    {
      field: 'TotalDiscountSku', headerName: 'Total Discount Value', headerAlign: "center", width: 100, editable: false, align: "center",
      valueFormatter: (params) => {
        const nf = new Intl.NumberFormat('en-US');
        const value = parseInt(params.value);
        return `$ ${nf.format(value)}`;
      }
    },
    { field: 'Description', headerName: 'Description', headerAlign: "center", width: 200, editable: false, renderCell: renderCellExpand, },
    { field: 'Category', headerName: 'Category', headerAlign: "center", width: 180, editable: false, headerAlign: "center", align: "center", renderCell: renderCellExpand, },

    { field: 'CaseQty', headerName: 'Case Quantity', width: 150, editable: false, hide: true },
    { field: 'Branch', headerName: 'Approval Branch', width: 180, editable: false, hide: true },
    { field: 'Justification', headerName: 'Justification', width: 180, editable: false, hide: true },
    { field: 'Remarks', headerName: 'Remarks', width: 180, editable: false, hide: true },
    { field: 'Approver', headerName: 'Approver', width: 180, editable: false, hide: true },
    { field: 'Duration', headerName: 'Duration', width: 180, editable: false, hide: true },

  ];

  const onAddRow = async (formInputs) => {
    setBlockSubmit(true);

    let ApprovalBranch = "";

    if (formInputs["NewMargin"] > 0 & formInputs["NewMargin"] > formInputs["CurrentMargin"] && formInputs["CurrentPrice"] != 0) {
      ApprovalBranch = "Branch 1";
    } else if ( (formInputs["NewMargin"] > 0 && formInputs["NewMargin"] < formInputs["CurrentMargin"]) && formInputs["CurrentPrice"] != 0) {
      ApprovalBranch = "Branch 2";
    } else {
      ApprovalBranch = "Branch 3";
    }


    let approversList = "";

    if (ApprovalBranch == "Branch 1") {
      approversList = "Automatically Approved";
    } else if (ApprovalBranch == "Branch 2") {
      approversList = formInputs["Approver"];
    } else if (ApprovalBranch == "Branch 3") {
      approversList = `${formInputs["Approver"]}, Liang Lin`;
    }else{
      approversList = "Error 1";
    }

    console.log("Approval Branch: ", ApprovalBranch, approversList);

    if (editingRow) {
      const CurrentRows = [...rows];
      const RowIndex = CurrentRows.findIndex((obj => obj.id == getValues("id")));

      if (rows.length <= 1) {
        setValue("RequestApprover", formInputs["ApproverEmail"]);
        setValue("RequestApproverName", formInputs["Approver"]);
      }

      CurrentRows[RowIndex] = {
        id: CurrentRows[RowIndex].id,
        Justification: formInputs["Justification"],
        Remarks: formInputs["Remarks"],
        Branch: ApprovalBranch,
        Approver: formInputs["Approver"],
        Approvers: approversList,

        Category: formInputs["Category"],
        SKU: formInputs["SKU"],
        Description: formInputs["Description"],
        CaseQty: formInputs["CaseQty"],

        MonthlyQuantity: formInputs["MonthlyQuantity"],
        NumberOfMonths: formInputs["NumberOfMonths"],
        TotalQuantity: formInputs["TotalQuantity"],

        CurrentPrice: formInputs["CurrentPrice"],
        StandardCost: formInputs["StandardCost"],
        CurrentMargin: formInputs["CurrentMargin"],
        CurrentPriceCase: formInputs["CurrentPriceCase"],
        TotalValueCurrent: formInputs["TotalValueCurrent"],

        NewPrice: formInputs["NewPrice"],
        NewMargin: formInputs["NewMargin"],
        NewPriceDiscount: formInputs["NewPriceDiscount"],
        NewPriceCase: formInputs["NewPriceCase"],
        CustomerCost: formInputs["CustomerCost"],
        TotalValueNewPrice: formInputs["TotalValueNewPrice"],
        TotalDiscountSku: formInputs["TotalDiscountSku"]
      }

      setrows(CurrentRows);
      setEditingRow(false);
    } else {

      if (rows.length <= 1) {
        setValue("RequestApprover", formInputs["ApproverEmail"]);
        setValue("RequestApproverName", formInputs["Approver"]);
      }

      setrows([...rows,
      {
        id: Math.round((Math.random() * 10000)),

        Justification: formInputs["Justification"],
        Remarks: formInputs["Remarks"],
        Branch: ApprovalBranch,
        Approver: formInputs["Approver"],
        Approvers: approversList,

        Category: formInputs["Category"],
        SKU: formInputs["SKU"],
        Description: formInputs["Description"],
        CaseQty: formInputs["CaseQty"],

        MonthlyQuantity: formInputs["MonthlyQuantity"],
        NumberOfMonths: formInputs["NumberOfMonths"],
        TotalQuantity: formInputs["TotalQuantity"],

        CurrentPrice: formInputs["CurrentPrice"],
        StandardCost: formInputs["StandardCost"],
        CurrentMargin: formInputs["CurrentMargin"],
        CurrentPriceCase: formInputs["CurrentPriceCase"],
        TotalValueCurrent: formInputs["TotalValueCurrent"],

        NewPrice: formInputs["NewPrice"],
        NewMargin: formInputs["NewMargin"],
        NewPriceDiscount: formInputs["NewPriceDiscount"],
        NewPriceCase: formInputs["NewPriceCase"],
        CustomerCost: formInputs["CustomerCost"],
        TotalValueNewPrice: formInputs["TotalValueNewPrice"],
        TotalDiscountSku: formInputs["TotalDiscountSku"]
      }]);

    }

    setValue("id", "");
    setValue("SKU", "");
    setValue("Category", "");
    setValue("Description", "");
    setValue("MonthlyQuantity", 0);
    setValue("NumberOfMonths", "12");
    setValue("TotalQuantity", 0);
    setValue("CaseQty", 0);

    setValue("CurrentPrice", 0);
    setValue("CurrentMargin", 0);
    setValue("CurrentPriceCase", 0);
    setValue("TotalValueCurrent", 0);

    setValue("NewPrice", 0);
    setValue("NewPriceDiscount", 0);
    setValue("NewPriceCase", 0);
    setValue("TotalValueNewPrice", 0);
    setValue("TotalDiscountSku", 0);

    setValue("Remarks", "");
    setValue("Approver", "");
    setValue("Justification", "");

    setBlockSubmit(false);

  };

  const onSubmit = async (formInputs) => {

    setBlockSubmit(true);

    let highestBranch = "";
    rows.map(row => {
      if (row["Branch"] === "Branch 3") {
        highestBranch = "Branch 3";
      } else if (row["Branch"] === "Branch 2" && highestBranch !== "Branch 3") {
        highestBranch = "Branch 2";
      } else if (row["Branch"] === "Branch 1" && highestBranch !== "Branch 3" && highestBranch !== "Branch 2") {
        highestBranch = "Branch 1";
      }
    });

    let createApprovalID = -1;
    let CreateApprovalRequestData = {}

    if (highestBranch === "Branch 1") {
      CreateApprovalRequestData = {
        "RequestType": formInputs["Request_Type"],
        "Requester": formInputs["Request_Requester"],
        "RequestStatus": "Approved",
        "RequestTitle": formInputs["Request_Title"],
        "RequestRegion": formInputs["Request_Region"],
        "RequestLocation": "None",
        "RequestFunction": "None",
        "RequestJson": {
          "RequesterEmail": instance.getAllAccounts()[0].username,
          "Customer": formInputs["Customer"],
          "CustomerZone": formInputs["CustomerZone"],
          "PriceList": formInputs["PriceList"],
          "SalesPerson": formInputs["SalesPerson"],
          "Currency": formInputs["Currency"],
          "BusinessType": formInputs["BusinessType"],
          "EffectiveDate": formInputs["EffectiveDate"],
          "Approver": formInputs["RequestApproverName"],
          "ApproverEmail": formInputs["RequestApprover"],
          "ApprovalRows": [...rows],
        }
      };
    } else {
      CreateApprovalRequestData = {
        "RequestType": formInputs["Request_Type"],
        "Requester": formInputs["Request_Requester"],
        "RequestStatus": `Awaiting Approval: ${formInputs["RequestApproverName"]}`,
        "RequestTitle": formInputs["Request_Title"],
        "RequestRegion": formInputs["Request_Region"],
        "RequestLocation": "None",
        "RequestFunction": "None",
        "RequestJson": {
          "RequesterEmail": instance.getAllAccounts()[0].username,
          "Customer": formInputs["Customer"],
          "CustomerZone": formInputs["CustomerZone"],
          "PriceList": formInputs["PriceList"],
          "SalesPerson": formInputs["SalesPerson"],
          "Currency": formInputs["Currency"],
          "BusinessType": formInputs["BusinessType"],
          "EffectiveDate": formInputs["EffectiveDate"],
          "Approver": formInputs["RequestApproverName"],
          "ApproverEmail": formInputs["RequestApprover"],
          "ApprovalRows": [...rows],
        }
      }
    }

    if(formInputs["RequestStatus"] === "Drafting"){
      createApprovalID = await createApprovalRequest(instance, accounts, "Price Approval", CreateApprovalRequestData );
    }else{
      updateApprovalRequest(instance, accounts, RequestID, CreateApprovalRequestData);
      createApprovalID = RequestID;
    }

    const emailData = {
      "EmailTo": instance.getAllAccounts()[0].username,
      "Subject": `Approval App: You have Submitted Request ${createApprovalID}`,
      "Title": "Approval Request Has Been Submitted",
      "EmailToName": instance.getAllAccounts()[0].name,
      "Messages": [`You have sumbitted a Price Approval Request ${createApprovalID}.`, `Status: ${CreateApprovalRequestData["RequestStatus"]}`],
      "RequestID": createApprovalID
    }

    sendEmail(instance, accounts, createApprovalID, emailData);

    let ApprovalList = [];

    if (highestBranch === "Branch 3") {

      let ApproverOne = {
        "RequestID": createApprovalID,
        "Approver": formInputs["RequestApprover"], //Approver["ApproverEmail"] ,
        "ApproverName": formInputs["RequestApproverName"],
        "ApproverType": "Single",
        "ApproverOrder": 1,
        "ApproverRole": "Approver",
        "Status": "Awaiting Approval",
        "CanEdit": 0,
        "IsCurrentApprover": 1,
        "ActionDate": "NULL",
        "FirstNotificationDate": "NULL",
        "LastNotificationDate": "NULL",
        "NotificationCount": "NULL",
        "ApprovalDate": "NULL"
      }
      let ApproverTwo = {
        "RequestID": createApprovalID,
        "Approver": "akardasz@sol-m.com", //"llin@sol-m.com", //Approver["ApproverEmail"] , ,
        "ApproverName": "Adrian",
        "ApproverType": "Single",
        "ApproverOrder": 2,
        "ApproverRole": "Approver",
        "Status": "",
        "CanEdit": 0,
        "IsCurrentApprover": 0,
        "ActionDate": "NULL",
        "FirstNotificationDate": "NULL",
        "LastNotificationDate": "NULL",
        "NotificationCount": "NULL",
        "ApprovalDate": "NULL"
      }

      ApprovalList = [ApproverOne, ApproverTwo];

    } else if (highestBranch === "Branch 2") {
      let ApproverOne = {
        "RequestID": createApprovalID,
        "Approver": formInputs["RequestApprover"], //Approver["ApproverEmail"] , ,
        "ApproverName": formInputs["RequestApproverName"],
        "ApproverType": "Single",
        "ApproverOrder": 1,
        "ApproverRole": "Approver",
        "Status": "Awaiting Approval",
        "CanEdit": 0,
        "IsCurrentApprover": 1,
        "ActionDate": "NULL",
        "FirstNotificationDate": "NULL",
        "LastNotificationDate": "NULL",
        "NotificationCount": "NULL",
        "ApprovalDate": "NULL"
      }
      ApprovalList = [ApproverOne];
    } else if (highestBranch === "Branch 1") {

      let ApproverOne = {
        "RequestID": createApprovalID,
        "Approver": "Automatic Approval",
        "ApproverName": "Automatic Approval",
        "ApproverType": "Single",
        "ApproverOrder": 1,
        "ApproverRole": "Automatic Approval",
        "Status": "Approved",
        "CanEdit": 0,
        "IsCurrentApprover": 1,
        "ActionDate": "NULL",
        "FirstNotificationDate": "NULL",
        "LastNotificationDate": "NULL",
        "NotificationCount": "NULL",
        "ApprovalDate": "NULL"
      }

      ApprovalList = [ApproverOne];
    }

    let data = {
      "ApproversList": [...ApprovalList]
    }

    ApprovalList.map(Approver => {
      if(Approver["Approver"] !== "Automatic Approval" && Approver["IsCurrentApprover"] === 1){
        const emailData = {
          "EmailTo": Approver["Approver"],
          "Subject": `Approval App: You Have Pending Actions On Price Approval Request ${createApprovalID}`,
          "Title": `Price Approval Request Has Been Submitted by ${instance.getAllAccounts()[0].name}`,
          "EmailToName": Approver["ApproverName"],
          "Messages": [`You have pending actions for Price Approval Request ${createApprovalID}.`, `Status: ${CreateApprovalRequestData["RequestStatus"]}`],
          "RequestID": createApprovalID
        }
        sendEmail(instance, accounts, createApprovalID, emailData);
      }
    })

    const approvers = await setApprovers(instance, accounts, data);
    setBlockSubmit(false);

    window.location.reload();
  }

  const onSaveDraft = async (formInputs) => {
    setBlockSubmit(true);

    //instance, accounts, requestType, requestData
    const createApproval = await updateApprovalRequest(instance, accounts, RequestID, {
      "RequestType": formInputs["Request_Type"],
      "Requester": formInputs["Request_Requester"],
      "RequestStatus": formInputs["Request_Status"],
      "RequestTitle": formInputs["Request_Title"],
      "RequestRegion": formInputs["Request_Region"],
      "RequestLocation": "None",
      "RequestFunction": "None",
      "RequestJson": {
        "RequesterEmail": formInputs["RequesterEmail"],
        "Customer": formInputs["Customer"],
        "CustomerZone": formInputs["CustomerZone"],
        "PriceList": formInputs["PriceList"],
        "SalesPerson": formInputs["SalesPerson"],
        "Currency": formInputs["Currency"],
        "BusinessType": formInputs["BusinessType"],
        "EffectiveDate": formInputs["EffectiveDate"],
        "Approver": formInputs["RequestApproverName"],
        "ApproverEmail": formInputs["RequestApprover"],
        "ApprovalRows": [...rows],
      }
    });

    //TODO Add Send Email to user stating they have saved draft. 
    const emailData = {
      "EmailTo": instance.getAllAccounts()[0].username,
      "Subject": `Approval App: You have saved draft for Request ${RequestID}`,
      "Title": "Approval Has Been Saved",
      "EmailToName": instance.getAllAccounts()[0].name,
      "Messages": [`You have saved changes Approval Request ${RequestID}.`],
      "RequestID": RequestID
    }

    sendEmail(instance, accounts, RequestID, emailData);
    setBlockSubmit(false);
    window.location.reload();
  };

  const regionProps = {
    options: ["", "NACA", "APAC", "EMEA", "SA", "HQ"],
    getOptionLabel: (option) => option,
  };

  const justificationProps = {
    options: ["", "Reduced to Meet Competitive Pricing.", "Reduction Meets Actual Savings.", "Reduction Available to All Purchasers."],
    getOptionLabel: (option) => option,
  };

  const priceListProps = {
    options: ["", "Distributor Price List", "Market Price List"],
    getOptionLabel: (option) => option,
  };

  const currencyListProps = {
    options: ["", "US", "CA"],
    getOptionLabel: (option) => option,
  };

  const onSendBack = async (formInputs) => {
    setBlockSubmit(true);

    const updateApproval = await updateApprovalRequest(instance, accounts, RequestID, {
      "RequestType": formInputs["Request_Type"],
      "Requester": formInputs["Request_Requester"],
      "RequestStatus": `Drafting: Sent Back To Requester - ${instance.getAllAccounts()[0].name}`,
      "RequestTitle": formInputs["Request_Title"],
      "RequestRegion": formInputs["Request_Region"],
      "RequestLocation": "None",
      "RequestFunction": "None",
      "RequestJson": {
        "RequesterEmail": formInputs["RequesterEmail"],
        "Customer": formInputs["Customer"],
        "CustomerZone": formInputs["CustomerZone"],
        "PriceList": formInputs["PriceList"],
        "SalesPerson": formInputs["SalesPerson"],
        "Currency": formInputs["Currency"],
        "BusinessType": formInputs["BusinessType"],
        "EffectiveDate": formInputs["EffectiveDate"],
        "Approver": formInputs["RequestApproverName"],
        "ApproverEmail": formInputs["RequestApprover"],
        "ApprovalRows": [...rows],
      }
    });

    let ApprovalList = []

    const parsedRequestApprovers = JSON.parse(RawRequestApprovers);

    parsedRequestApprovers.map(approver => {
      let ApproverOne = {}
      if (approver["IsCurrentApprover"] === true) {
        ApproverOne = {
          "RequestID": parseInt(approver["RequestID"]),
          "Approver": approver["Approver"],
          "ApproverName": approver["ApproverName"],
          "ApproverType": approver["ApproverType"],
          "ApproverOrder": approver["ApproverOrder"],
          "ApproverRole": approver["ApproverRole"],
          "Status": "Sent Back",
          "CanEdit": approver["CanEdit"],
          "IsCurrentApprover": 0,
          "ActionDate": "set",
          "FirstNotificationDate": approver["FirstNotificationDate"],
          "LastNotificationDate": approver["LastNotificationDate"],
          "NotificationCount": approver["NotificationCount"],
          "ApprovalDate": approver["ApprovalDate"]
        }
      } else {
        ApproverOne = {
          "RequestID": parseInt(approver["RequestID"]),
          "Approver": approver["Approver"],
          "ApproverName":  approver["ApproverName"],
          "ApproverType": approver["ApproverType"],
          "ApproverOrder": approver["ApproverOrder"],
          "ApproverRole": approver["ApproverRole"],
          "Status": "",
          "CanEdit": approver["CanEdit"],
          "IsCurrentApprover": 0,
          "ActionDate": approver["ActionDate"],
          "FirstNotificationDate": approver["FirstNotificationDate"],
          "LastNotificationDate": approver["LastNotificationDate"],
          "NotificationCount": approver["NotificationCount"],
          "ApprovalDate": approver["ApprovalDate"]
        }
      }
      ApprovalList = [...ApprovalList, ApproverOne]
    }

    );

    let data = {
      "ApproversList": [...ApprovalList]
    }

    const ApproverEmailData = {
      "EmailTo": instance.getAllAccounts()[0].username,
      "Subject": `Approval App: You Have Sent Price Approval Request ${RequestID} Back To The Requester`,
      "Title": "Approval Has Been Sent Back",
      "EmailToName": instance.getAllAccounts()[0].name,
      "Messages": [`You have sent back Price Approval Request ${RequestID} to ${formInputs["Request_Requester"]} for review.`],
      "RequestID": RequestID
    }
    sendEmail(instance, accounts, RequestID, ApproverEmailData);

    const RequestEmailData = {
      "EmailTo": formInputs["RequesterEmail"],
      "Subject": `Approval App: Price Approval Request Pending Review`,
      "Title": "Approval Request Review Pending",
      "EmailToName": formInputs["Requester"],
      "Messages": [`${instance.getAllAccounts()[0].name} has sent back Price Approval Request ${RequestID} for review.`],
      "RequestID": RequestID
    }
    sendEmail(instance, accounts, RequestID, RequestEmailData);

    //TODO Send Email to Requester Stating Sent Back

    //TODO Send Email to Actioned Approvers Statiing Approver has SentBack
    const approvers = await setApprovers(instance, accounts, data);
    setBlockSubmit(false);
    window.location.reload();

  }


  const onDecline = async (formInputs) => {
    setBlockSubmit(true);

    const updateApproval = await updateApprovalRequest(instance, accounts, RequestID, {
      "RequestType": formInputs["Request_Type"],
      "Requester": formInputs["Request_Requester"],
      "RequestStatus": `Declined - ${instance.getAllAccounts()[0].name}`,
      "RequestTitle": formInputs["Request_Title"],
      "RequestRegion": formInputs["Request_Region"],
      "RequestLocation": "None",
      "RequestFunction": "None",
      "RequestJson": {
        "RequesterEmail": formInputs["RequesterEmail"],
        "Customer": formInputs["Customer"],
        "CustomerZone": formInputs["CustomerZone"],
        "PriceList": formInputs["PriceList"],
        "SalesPerson": formInputs["SalesPerson"],
        "Currency": formInputs["Currency"],
        "BusinessType": formInputs["BusinessType"],
        "EffectiveDate": formInputs["EffectiveDate"],
        "Approver": formInputs["RequestApproverName"],
        "ApproverEmail": formInputs["RequestApprover"],
        "ApprovalRows": [...rows],
      }
    });

    let ApprovalList = []

    const parsedRequestApprovers = JSON.parse(RawRequestApprovers)

    await parsedRequestApprovers.map(approver => {
      let ApproverOne = {}
      if (approver["IsCurrentApprover"] === true) {
        ApproverOne = {
          "RequestID": parseInt(approver["RequestID"]),
          "Approver": approver["Approver"],
          "ApproverName": approver["ApproverName"],
          "ApproverType": approver["ApproverType"],
          "ApproverOrder": approver["ApproverOrder"],
          "ApproverRole": approver["ApproverRole"],
          "Status": "Declined",
          "CanEdit": approver["CanEdit"],
          "IsCurrentApprover": 0,
          "ActionDate": "set",
          "FirstNotificationDate": approver["FirstNotificationDate"],
          "LastNotificationDate": approver["LastNotificationDate"],
          "NotificationCount": approver["NotificationCount"],
          "ApprovalDate": "set"
        }
      } else {
        ApproverOne = {
          "RequestID": parseInt(approver["RequestID"]),
          "Approver": approver["Approver"],
          "ApproverName": approver["ApproverName"],
          "ApproverType": approver["ApproverType"],
          "ApproverOrder": approver["ApproverOrder"],
          "ApproverRole": approver["ApproverRole"],
          "Status": approver["Status"] == "" ?  "N/A" : approver["Status"],
          "CanEdit": approver["CanEdit"],
          "IsCurrentApprover": 0,
          "ActionDate": approver["ActionDate"],
          "FirstNotificationDate": approver["FirstNotificationDate"],
          "LastNotificationDate": approver["LastNotificationDate"],
          "NotificationCount": approver["NotificationCount"],
          "ApprovalDate": approver["ApprovalDate"]
        }
      }
      ApprovalList = [...ApprovalList, ApproverOne]
    }

    );

    let data = {
      "ApproversList": [...ApprovalList]
    }
    //TODO Send Email to Requester Stating Declined

    const RequesterEmailData = {
      "EmailTo": formInputs["RequesterEmail"],
      "Subject": `Approval App: Price Approval Request ${RequestID} Has Been Declined`,
      "Title": "Approval Has Been Declined.",
      "EmailToName": formInputs["Requester"],
      "Messages": [`${instance.getAllAccounts()[0].name} has declined Price Approval Request ${RequestID}`],
      "RequestID": RequestID
    }
    sendEmail(instance, accounts, RequestID, RequesterEmailData);

    const ApproverEmailData = {
      "EmailTo": instance.getAllAccounts()[0].username,
      "Subject": `Approval App: You Have Declined Price Approval Request ${RequestID}`,
      "Title": "Approval Has Been Declined",
      "EmailToName": instance.getAllAccounts()[0].name,
      "Messages": [`You have declined Approval Request ${RequestID}.`],
      "RequestID": RequestID
    }
    sendEmail(instance, accounts, RequestID, ApproverEmailData);

    //TODO Send Email to Actioned Approvers Statiing Approver has Declined
    const approvers = await setApprovers(instance, accounts, data);
    setBlockSubmit(false);
    window.location.reload();

  }

  const onAccept = async (formInputs) => {
    setBlockSubmit(true);

    const parsedRequestApprovers = JSON.parse(RawRequestApprovers);

    let ApprovalList = [];

    let CurrentApproverRecord = "";
    let NextApproverRecord = "";

    if (isCurrentApprover === true) {
      CurrentApproverRecord = parsedRequestApprovers.find(approver => {
        if (instance.getAllAccounts()[0].username === approver["Approver"] && approver["IsCurrentApprover"] === true) {
          return approver
        }
      });

      console.log("1. Current Approver: ");
      console.log(CurrentApproverRecord);

      const nextPossibleApprover = parseInt(CurrentApproverRecord["ApproverOrder"]) + 1

      console.log("2. Next Possible Approver INT: ");
      console.log(nextPossibleApprover);


      NextApproverRecord = parsedRequestApprovers?.find(approver => {
        if (parseInt(approver["ApproverOrder"]) === nextPossibleApprover) {
          return approver
        }
      });

      console.log("3. Next Approver: ");
      console.log(NextApproverRecord);

    }

    parsedRequestApprovers.map(approver => {
      let ApproverOne = {}

      if (instance.getAllAccounts()[0].username === approver["Approver"] && approver["IsCurrentApprover"] === true) {
        ApproverOne = {
          "RequestID": parseInt(approver["RequestID"]),
          "Approver": approver["Approver"],
          "ApproverName": approver["ApproverName"],
          "ApproverType": approver["ApproverType"],
          "ApproverOrder": approver["ApproverOrder"],
          "ApproverRole": approver["ApproverRole"],
          "Status": "Approved",
          "CanEdit": approver["CanEdit"],
          "IsCurrentApprover": 0,
          "ActionDate": "set",
          "FirstNotificationDate": approver["FirstNotificationDate"],
          "LastNotificationDate": approver["LastNotificationDate"],
          "NotificationCount": approver["NotificationCount"],
          "ApprovalDate": "set"
        }
      } else if (NextApproverRecord !== "" && NextApproverRecord !== undefined && NextApproverRecord["ApproverOrder"] === approver["ApproverOrder"]) {
        ApproverOne = {
          "RequestID": parseInt(approver["RequestID"]),
          "Approver": approver["Approver"],
          "ApproverName": approver["ApproverName"],
          "ApproverType": approver["ApproverType"],
          "ApproverOrder": approver["ApproverOrder"],
          "ApproverRole": approver["ApproverRole"],
          "Status": approver["Status"],
          "CanEdit": approver["CanEdit"],
          "IsCurrentApprover": 1,
          "ActionDate": approver["ActionDate"],
          "FirstNotificationDate": approver["FirstNotificationDate"],
          "LastNotificationDate": approver["LastNotificationDate"],
          "NotificationCount": approver["NotificationCount"],
          "ApprovalDate": approver["ApprovalDate"]
        }
      } else {
        ApproverOne = {
          "RequestID": parseInt(approver["RequestID"]),
          "Approver": approver["Approver"],
          "ApproverName": approver["ApproverName"],
          "ApproverType": approver["ApproverType"],
          "ApproverOrder": approver["ApproverOrder"],
          "ApproverRole": approver["ApproverRole"],
          "Status": approver["Status"],
          "CanEdit": approver["CanEdit"],
          "IsCurrentApprover": 0,
          "ActionDate": approver["ActionDate"],
          "FirstNotificationDate": approver["FirstNotificationDate"],
          "LastNotificationDate": approver["LastNotificationDate"],
          "NotificationCount": approver["NotificationCount"],
          "ApprovalDate": approver["ApprovalDate"]
        }
      }

      ApprovalList = [...ApprovalList, ApproverOne]
    }
    );

    console.log("4. ApprovalList: ");
    console.log(ApprovalList);

    if (NextApproverRecord !== "" && NextApproverRecord !== undefined) {
      console.log("NEXT APPROVER: ")
      console.log(
        {
          "RequestType": formInputs["Request_Type"],
          "Requester": formInputs["Request_Requester"],
          "RequestStatus": `Awaiting Approval - ${NextApproverRecord?.["ApproverName"]}`,
          "RequestTitle": formInputs["Request_Title"],
          "RequestRegion": formInputs["Request_Region"],
          "RequestLocation": "None",
          "RequestFunction": "None",
      "RequestJson": {
            "RequesterEmail": formInputs["RequesterEmail"],
            "Customer": formInputs["Customer"],
            "CustomerZone": formInputs["CustomerZone"],
            "PriceList": formInputs["PriceList"],
            "SalesPerson": formInputs["SalesPerson"],
            "Currency": formInputs["Currency"],
            "BusinessType": formInputs["BusinessType"],
            "EffectiveDate": formInputs["EffectiveDate"],
            "Approver": formInputs["Approver"],
            "ApproverEmail": formInputs["ApproverEmail"],
            "ApprovalRows": [...rows],
          }
        });

        const ApproverEmailData = {
          "EmailTo": instance.getAllAccounts()[0].username,
          "Subject": `Approval App: Price Approval Request ${RequestID} Has Been Updated.`,
          "Title": "Price Approval Request Approved.",
          "EmailToName": instance.getAllAccounts()[0].name,
          "Messages": [`You have Approved Approval Request ${RequestID} as approver ${CurrentApproverRecord["ApproverOrder"]}.`, `Status: Awaiting Approval - ${NextApproverRecord?.["ApproverName"]}`],
          "RequestID": RequestID
        }
        sendEmail(instance, accounts, RequestID, ApproverEmailData);

        const NextApproverEmailData = {
          "EmailTo": NextApproverRecord?.["Approver"],
          "Subject": `Approval App: Price Approval Request ${RequestID} Pending Review.`,
          "Title": "Price Approval Request Pending Review.",
          "EmailToName": NextApproverRecord?.["ApproverName"],
          "Messages": [`Please Review Price Approval Request ${RequestID} and take action accordingly.`, `Status: Awaiting Approval - ${NextApproverRecord?.["ApproverName"]}`],
          "RequestID": RequestID
        }
        sendEmail(instance, accounts, RequestID, NextApproverEmailData);
  
        const RequesterEmailData = {
          "EmailTo": formInputs["RequesterEmail"],
          "Subject": `Approval App: Price Approval Request ${RequestID} Has Been Updated`,
          "Title": "Price Approval Request Has Been Approved.",
          "EmailToName": formInputs["Requester"],
          "Messages": [`${instance.getAllAccounts()[0].name} has Approved Price Approval Request ${RequestID} as approver ${CurrentApproverRecord["ApproverOrder"]}`, `Status: Awaiting Approval - ${NextApproverRecord?.["ApproverName"]}`],
          "RequestID": RequestID
        }
        sendEmail(instance, accounts, RequestID, RequesterEmailData);

      const updateApproval = await updateApprovalRequest(instance, accounts, RequestID, {
            "RequestType": formInputs["Request_Type"],
            "Requester": formInputs["Request_Requester"],
            "RequestStatus": `Awaiting Approval - ${NextApproverRecord?.["ApproverName"]}`,
            "RequestTitle": formInputs["Request_Title"],
            "RequestRegion": formInputs["Request_Region"],
            "RequestLocation": "None",
            "RequestFunction": "None",
            "RequestJson": {
              "RequesterEmail": formInputs["RequesterEmail"],
              "Customer": formInputs["Customer"],
              "CustomerZone": formInputs["CustomerZone"],
              "PriceList": formInputs["PriceList"],
              "SalesPerson": formInputs["SalesPerson"],
              "Currency": formInputs["Currency"],
              "BusinessType": formInputs["BusinessType"],
              "EffectiveDate": formInputs["EffectiveDate"],
              "Approver": formInputs["RequestApproverName"],
              "ApproverEmail": formInputs["RequestApprover"],
              "ApprovalRows": [...rows],
            }
          });
    } else {
      console.log("FINAL APPROVAL Approved: ")
      console.log(
        {
          "RequestType": formInputs["Request_Type"],
          "Requester": formInputs["Request_Requester"],
          "RequestStatus": `Approved - ${instance.getAllAccounts()[0].name}`,
          "RequestTitle": formInputs["Request_Title"],
          "RequestRegion": formInputs["Request_Region"],
          "RequestLocation": "None",
          "RequestFunction": "None",
      "RequestJson": {
            "RequesterEmail": formInputs["RequesterEmail"],
            "Customer": formInputs["Customer"],
            "CustomerZone": formInputs["CustomerZone"],
            "PriceList": formInputs["PriceList"],
            "SalesPerson": formInputs["SalesPerson"],
            "Currency": formInputs["Currency"],
            "BusinessType": formInputs["BusinessType"],
            "EffectiveDate": formInputs["EffectiveDate"],
            "Approver": formInputs["Approver"],
            "ApproverEmail": formInputs["ApproverEmail"],
            "ApprovalRows": [...rows],
          }
        }
      )

      const ApproverEmailData = {
        "EmailTo": instance.getAllAccounts()[0].username,
        "Subject": `Approval App: Price Approval Request ${RequestID} Has Been Completed - Approved`,
        "Title": "Price Approval Request Approved.",
        "EmailToName": instance.getAllAccounts()[0].name,
        "Messages": [`You have Approved Approval Request ${RequestID}.`, "This was the final approval required, the request is now approved."],
        "RequestID": RequestID
      }
      sendEmail(instance, accounts, RequestID, ApproverEmailData);

      const RequesterEmailData = {
        "EmailTo": formInputs["RequesterEmail"],
        "Subject": `Approval App: Price Approval Request ${RequestID} Has Been Approved`,
        "Title": "Price Approval Request Has Been Approved.",
        "EmailToName": formInputs["Requester"],
        "Messages": [`${instance.getAllAccounts()[0].name} has Approved Price Approval Request ${RequestID}`, "This was the final approval required, the request is now approved."],
        "RequestID": RequestID
      }
      sendEmail(instance, accounts, RequestID, RequesterEmailData);

      const SalesOperationsEmailData = {
        "EmailTo": "cschneider@sol-m.com",
        "Subject": `Approval App: Price Approval Request ${RequestID} Pending Review`,
        "Title": "Price Approval Request Has Been Approved.",
        "EmailToName": "Christopher Schneider",
        "Messages": [`${instance.getAllAccounts()[0].name} has Approved and completed Price Approval Request ${RequestID}`, 'Please review the request and taking action accordingly.'],
        "RequestID": RequestID
      }
      sendEmail(instance, accounts, RequestID, SalesOperationsEmailData);

      
      const updateApproval = await updateApprovalRequest(instance, accounts, RequestID, {
        "RequestType": formInputs["Request_Type"],
        "Requester": formInputs["Request_Requester"],
        "RequestStatus": `Approved - ${instance.getAllAccounts()[0].name}`,
        "RequestTitle": formInputs["Request_Title"],
        "RequestRegion": formInputs["Request_Region"],
        "RequestLocation": "None",
        "RequestFunction": "None",
        "RequestJson": {
            "RequesterEmail": formInputs["RequesterEmail"],
            "Customer": formInputs["Customer"],
            "CustomerZone": formInputs["CustomerZone"],
            "PriceList": formInputs["PriceList"],
            "SalesPerson": formInputs["SalesPerson"],
            "Currency": formInputs["Currency"],
            "BusinessType": formInputs["BusinessType"],
            "EffectiveDate": formInputs["EffectiveDate"],
            "Approver": formInputs["RequestApproverName"],
            "ApproverEmail": formInputs["RequestApprover"],
            "ApprovalRows": [...rows],
        }
      });
    }

    console.log(ApprovalList);
    let data = {
      "ApproversList": [...ApprovalList]
    }
    console.log(data)
    //TODO Send Email to Requester Stating Declined

    //TODO Send Email to Actioned Approvers Statiing Approver has Declined

    const approvers = await setApprovers(instance, accounts, data);
    setBlockSubmit(false);
    window.location.reload();

  }



  // Add update existing rows logic on customer change 
  async function performCustomerActions(item) {
    console.log("Performed Autocomplete Action: ", item);

    const SelectedCustomerData = CustomersData.find(element => element["Customer"] == item);

    setValue("SalesPerson", SelectedCustomerData["SalesEmployeeName"], { shouldTouch: false });
    setValue("CustomerZone", SelectedCustomerData["Zone"], { shouldTouch: false });
    setValue("PriceList", SelectedCustomerData["ListName"], { shouldTouch: false });

    setValue("SKU", "");
    setValue("Category", "");
    setValue("Description", "");
    setValue("MonthlyQuantity", 0);
    setValue("NumberOfMonths", "12");
    setValue("TotalQuantity", 0);
    setValue("CaseQty", 0);

    setValue("CurrentPrice", 0);
    setValue("CurrentMargin", 0);
    setValue("CurrentPriceCase", 0);

    setValue("TotalValueCurrent", 0);
    setValue("NewPrice", 0);
    setValue("NewPriceDiscount", 0);
    setValue("NewPriceCase", 0);
    setValue("TotalValueNewPrice", 0);
    setValue("TotalDiscountSku", 0);

    setValue("Remarks", "");
    setValue("Approver", "");
    setValue("Justification", "");

    updatedRowsOnCustomerChange();


  }

  async function performSalesRepActions(item) {

    console.log("Performing SalesRep Actions for: ", item);

    if (item == "" || item == null || item == undefined) {

      const CustomerOptionsList = new Set(CustomersData.map((data) => data["Customer"]));
      const SalesEmployeeOptionsList = new Set(CustomersData.map((data) => data["SalesEmployeeName"]));

      setCustomerOptions([...CustomerOptionsList]);
      setSalesEmployeeOptions([...SalesEmployeeOptionsList]);

      setValue("Customer", "");
      setValue("CustomerZone", "ZoneU-Parcel");
      setValue("PriceList", "Distributor Price List");

    } else {

      const currentCustomer = getValues("Customer");

      const selectedSalesRepCustomers = CustomersData.filter(element => element["SalesEmployeeName"] == item);
      const selectedSalesRepCustomerList = [...new Set(selectedSalesRepCustomers.map(data => data["Customer"]))];

      if (currentCustomer == "" && currentCustomer == undefined) {

        setCustomerOptions([...selectedSalesRepCustomerList]);
        setValue("CustomerZone", "ZoneU-Parcel");
        setValue("PriceList", "Distributor Price List");

      } else if (!selectedSalesRepCustomerList.includes(currentCustomer)) {

        setCustomerOptions([...selectedSalesRepCustomerList]);
        setValue("CustomerZone", "ZoneU-Parcel");
        setValue("PriceList", "Distributor Price List");
        setValue("Customer", "");

      }

    }

    // Always Reset Fields on Sales Rep User Change
    setValue("SKU", "");
    setValue("Category", "");
    setValue("Description", "");
    setValue("MonthlyQuantity", 0);
    setValue("NumberOfMonths", "12");
    setValue("TotalQuantity", 0);
    setValue("CaseQty", 0);

    setValue("CurrentPrice", 0);
    setValue("CurrentMargin", 0);
    setValue("CurrentPriceCase", 0);

    setValue("TotalValueCurrent", 0);
    setValue("NewPrice", 0);
    setValue("NewPriceDiscount", 0);
    setValue("NewPriceCase", 0);
    setValue("TotalValueNewPrice", 0);
    setValue("TotalDiscountSku", 0);

    setValue("Remarks", "");
    setValue("Approver", "");
    setValue("Justification", "");

    console.log("Completed SalesRep Actions for: ", item);
  }

  async function performMonthlyQuantityActions(item) {
    console.log("Performed Autocomplete Action: ", item);

    const TotalQuantityCalculation = parseFloat(item) * parseInt(getValues("NumberOfMonths"));
    setValue("TotalQuantity", TotalQuantityCalculation.toFixed(0));

    let TotalCurrentPriceCalc = 0;
    let TotalValueNewPriceCalc = 0;

    if (getValues("CurrentPrice") != 0 && getValues("CurrentPrice") != undefined) {
      TotalCurrentPriceCalc = parseFloat(getValues("CurrentPrice")) * TotalQuantityCalculation;
      setValue("TotalValueCurrent", TotalCurrentPriceCalc.toFixed(0));
    }

    if (getValues("NewPrice") != 0 && getValues("NewPrice") != undefined) {
      TotalValueNewPriceCalc = parseFloat(getValues("NewPrice")) * TotalQuantityCalculation;
      setValue("TotalValueNewPrice", TotalValueNewPriceCalc.toFixed(0));
    }

    if (TotalCurrentPriceCalc != 0 && TotalValueNewPriceCalc != 0) {
      const DiscountValueCalc = TotalCurrentPriceCalc - TotalValueNewPriceCalc;
      setValue("TotalDiscountSku", DiscountValueCalc.toFixed(0));
    }
  }

  async function performNumberOfMonthsActions(item) {
    const TotalQuantityCalculation = parseFloat(getValues("MonthlyQuantity")) * parseFloat(item);
    setValue("TotalQuantity", TotalQuantityCalculation.toFixed(0));

    let TotalCurrentPriceCalc = 0;
    let TotalValueNewPriceCalc = 0;

    if (getValues("CurrentPrice") != 0 && getValues("CurrentPrice") != undefined) {
      TotalCurrentPriceCalc = parseFloat(getValues("CurrentPrice")) * TotalQuantityCalculation;
      setValue("TotalValueCurrent", TotalCurrentPriceCalc.toFixed(0));
    }

    if (getValues("NewPrice") != 0 && getValues("NewPrice") != undefined) {
      TotalValueNewPriceCalc = parseFloat(getValues("NewPrice")) * TotalQuantityCalculation;
      setValue("TotalValueNewPrice", TotalValueNewPriceCalc.toFixed(0));
    }

    if (TotalCurrentPriceCalc != 0 && TotalValueNewPriceCalc != 0) {
      const DiscountValueCalc = TotalCurrentPriceCalc - TotalValueNewPriceCalc;
      setValue("TotalDiscountSku", DiscountValueCalc.toFixed(0));
    }

  }

  async function performSKUActions(item) {
    if (item == "" || item == undefined || item == null) {
      console.log("TEST")
      setValue("Description", "");
      setValue("MonthlyQuantity", 0);
      setValue("NumberOfMonths", "12");
      setValue("TotalQuantity", 0);
      setValue("CaseQty", 0);

      setValue("CurrentPrice", 0);
      setValue("CurrentMargin", 0);
      setValue("CurrentPriceCase", 0);

      setValue("TotalValueCurrent", 0);
      setValue("NewPrice", 0);
      setValue("NewPriceDiscount", 0);
      setValue("NewPriceCase", 0);
      setValue("TotalValueNewPrice", 0);
      setValue("TotalDiscountSku", 0);

      setValue("Remarks", "");
      setValue("Approver", "");
      setValue("Justification", "");

    } else {
      const SkuDataList = PriceListData.find(element => element["LabelCode"] === item);
      const currentCategory = getValues("Category");

      if (currentCategory == "" || currentCategory != SkuDataList?.["Category"] || currentCategory == null || currentCategory) {
        setValue("Category", SkuDataList?.["Category"] || SkuDataList?.["Category"]);
      }

      const CasePrice = parseInt(SkuDataList?.["CaseQty"]) * parseFloat(SkuDataList?.["Price(Each)"]);

      setValue("CurrentPriceCase", CasePrice.toFixed(2));
      setValue("CurrentPrice", SkuDataList?.["Price(Each)"] || SkuDataList?.["Price(Each)"]);
      setValue("CaseQty", SkuDataList?.["CaseQty"] || SkuDataList?.["CaseQty"]);
      setValue("Description", SkuDataList?.["Description"] || SkuDataList?.["Description"]);

      setCosts("Sku", { "Sku": item, "Price": SkuDataList?.["Price(Each)"], "Zone": getValues("CustomerZone") }, setValue);


      const TotalValueCurrent = parseFloat(SkuDataList?.["Price(Each)"]) * parseFloat(getValues("TotalQuantity"));
      setValue("TotalValueCurrent", TotalValueCurrent.toFixed(0));

      setValue("NewPriceDiscount", 0);
      setValue("NewPrice", 0);
      setValue("NewPriceCase", 0);
      setValue("TotalDiscountSku", 0);
      setValue("TotalValueNewPrice", 0);


      const ApproverRequestFilters = {
        "RequestFilters": [
          { "SqlFunction": "json", "ComparisonOperator": "in", "LogicalOperator": "none", "Key": "Category", "Value": [SkuDataList?.["Category"] || SkuDataList?.["Category"]] },
          { "SqlFunction": "none", "ComparisonOperator": "eq", "LogicalOperator": "and", "Key": "ApproverOrder", "Value": 1 }
        ]
      }

      const CategoryApproversList = await getApprovers(instance, accounts, getValues("Request_Type"), ApproverRequestFilters);

      const ApproverReturned = JSON.parse(CategoryApproversList);

      setValue("Approver", ApproverReturned[0]["ApproverName"]);
      setValue("ApproverEmail", ApproverReturned[0]["ApproverEmail"]);

    }
  }

  async function performCategoryActions(item) {
    setValue("SKU", "");

    if (item == "" || item == undefined || item == null) {
      const newSkuOptions = [...new Set(PriceListData.map(data => data["LabelCode"]))];
      setSkuOptions(["", ...newSkuOptions]);

      const newCategoryOptions = [...new Set(PriceListData.map(data => data["Category"]))];
      setCategoryOptions(["", ...newCategoryOptions]);

      setValue("Approver", "");
    } else {

      const ApproverRequestFilters = {
        "RequestFilters": [
          { "SqlFunction": "json", "ComparisonOperator": "in", "LogicalOperator": "none", "Key": "Category", "Value": [item] },
          { "SqlFunction": "none", "ComparisonOperator": "eq", "LogicalOperator": "and", "Key": "ApproverOrder", "Value": 1 }
        ]
      }

      const CategoryApproversList = await getApprovers(instance, accounts, getValues("Request_Type"), ApproverRequestFilters);
      const ApproverReturned = JSON.parse(CategoryApproversList);

      setValue("Approver", ApproverReturned[0]["ApproverName"]);
      setValue("ApproverEmail", ApproverReturned[0]["ApproverEmail"]);

      const CategoryRequestFilters = {
        "RequestFilters": [
          { "SqlFunction": "none", "ComparisonOperator": "eq", "LogicalOperator": "none", "Key": "ApproverEmail", "Value": ApproverReturned[0]["ApproverEmail"] },
        ]
      }

      const ApproverCategoriesList = await getApprovers(instance, accounts, getValues("Request_Type"), CategoryRequestFilters);
      const CategoriesReturned = JSON.parse(ApproverCategoriesList).map(data => data["RequestSpecificJson"]["Category"]);

      let newSkuList = []

      newSkuList = [...PriceListData.filter(data => (item == data["Category"]))];

      const newSkuOptions = [...new Set(newSkuList.map(data => data["LabelCode"]))];
      setSkuOptions(["", ...newSkuOptions]);

    }

  }

  async function performNewPriceActions(item) {
    console.log("Performed Autocomplete Action: ", item);

    if (getValues("CurrentPrice") != 0 && item != 0 && item != undefined) {
      const increase = item - getValues("CurrentPrice");
      const incPerc = parseFloat((increase / getValues("CurrentPrice")) * 100).toFixed(2);

      if (Math.sign(incPerc) == -1) {
        setValue("NewPriceDiscount", Math.abs(incPerc));
      }
      if (Math.sign(incPerc) == 1) {
        setValue("NewPriceDiscount", -(incPerc));
      }
      if (Math.sign(incPerc) == 0) {
        setValue("NewPriceDiscount", -(incPerc));
      }

      const CasePrice = (parseInt(getValues("CaseQty")) * parseFloat(item)).toFixed(2);
      setValue("NewPriceCase", CasePrice);
      setCosts("NewPrice", { "Sku": getValues("SKU"), "Price": item, "Zone": getValues("CustomerZone") }, setValue, instance, accounts);

      const TotalValueNewPrice = parseFloat(item) * parseFloat(getValues("TotalQuantity"));
      setValue("TotalValueNewPrice", TotalValueNewPrice.toFixed(0));

      let TotalCurrentPriceCalc = 0;

      if (getValues("CurrentPrice") != 0 && getValues("CurrentPrice") != undefined) {
        TotalCurrentPriceCalc = parseFloat(getValues("CurrentPrice")) * getValues("TotalQuantity");
        setValue("TotalValueCurrent", TotalCurrentPriceCalc.toFixed(0));
      }

      if (TotalCurrentPriceCalc != 0 && TotalValueNewPrice != 0) {
        const DiscountValueCalc = TotalCurrentPriceCalc - TotalValueNewPrice;
        setValue("TotalDiscountSku", DiscountValueCalc.toFixed(0));
      }

    }

  }

  async function performNewDiscountActions(item) {
    console.log("Performed Autocomplete Action: ", item);

    if (getValues("SKU") !== "" && getValues("Category") !== "" && getValues("CurrentPrice") !== "" && getValues("NewPrice") !== "") {
      const discount = item / 100;
      const discountCalculation = parseFloat(getValues("CurrentPrice") - (getValues("CurrentPrice") * discount)).toFixed(4);
      setValue("NewPrice", discountCalculation);

      const CasePrice = (parseInt(getValues("CaseQty")) * parseFloat(discountCalculation)).toFixed(2);
      setValue("NewPriceCase", CasePrice);

      setCosts("NewPrice", { "Sku": getValues("SKU"), "Price": discountCalculation, "Zone": getValues("CustomerZone") }, setValue, instance, accounts);

      const TotalValueNewPrice = parseFloat(discountCalculation) * parseFloat(getValues("TotalQuantity"));
      setValue("TotalValueNewPrice", TotalValueNewPrice.toFixed(0));

      let TotalCurrentPriceCalc = 0;

      if (getValues("CurrentPrice") != 0 && getValues("CurrentPrice") != undefined) {
        TotalCurrentPriceCalc = parseFloat(getValues("CurrentPrice")) * getValues("TotalQuantity");
        setValue("TotalValueCurrent", TotalCurrentPriceCalc.toFixed(0));
      } else {
        setValue("TotalDiscountSku", 0);
      }
      if (TotalCurrentPriceCalc != 0 && TotalValueNewPrice != 0) {
        const DiscountValueCalc = TotalCurrentPriceCalc - TotalValueNewPrice;
        setValue("TotalDiscountSku", DiscountValueCalc.toFixed(2));
      } else {
        setValue("TotalDiscountSku", 0);
      }

    }

  }


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid pt={1} width={1} container rowGap={2}>
        <Grid item container pl={2} pr={2} spacing={2} xs={12}>
          <Grid item xs={4} zeroMinWidth>
            <TextField
              {...register("Request_Title")}
              name="Request_Title"
              label="Request Title"
              variant="filled"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4} zeroMinWidth>
            <ControlledDatePicker
              control={control}
              name="Request_Date"
              readOnly
              label="Request Date"
              renderInput={(params) => <TextField variant="filled" fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={4} zeroMinWidth>
            <TextField
              {...register("Request_Status")}
              name="RequestStatus"
              label="Status"
              fullWidth
              variant="filled"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          pl={2} pr={2}
          container
          spacing={2}
          sx={{ xs: 12, justifyContent: "space-evenly", alignItems: "center" }}
        >
          <Grid item xs={4} zeroMinWidth>
            <TextField
              {...register("Request_Requester")}
              fullWidth
              label="Requester"
              variant="filled"
              InputProps={{
                readOnly: true,
              }}
              size="normal"
            />
          </Grid>
          <Grid item xs={4} zeroMinWidth>
            <TextField
              {...register("Request_Type")}
              name="RequestType"
              fullWidth
              label="Request Type"
              variant="filled"
              InputProps={{
                readOnly: true,
              }}
              size="normal"
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledAutocompleteV2
              control={control}
              name="Request_Region"
              options={regionProps["options"]}
              getOptionLabel={regionProps["getOptionLabel"]}
              renderInput={(params) => {  
                  
                if(isViewer){
                  return (
                    <TextField {...params}
                      InputProps={{...params.inputProps, readOnly: true}}
                      variant={isViewer ? "filled" : "outlined"}
                      InputLabelProps={{ shrink: true }}
                      label="SKU" 
                    />
                  )
                }else{
                  return (
                    <TextField {...params}
                              variant={isViewer ? "filled" : "outlined"}
                              InputLabelProps={{ shrink: true }}
                              label="SKU" 
                    />
                  )
                }
  
              }}
            />
          </Grid>


        </Grid>
        <Grid
          item
          container
          spacing={2}
          pl={2} pr={2}
          xs={12}
          justifyContent="space-evenly"
          alignItems="center"
        >


          <Grid item xs={6} zeroMinWidth>
            <ControlledAutocompleteV2
              control={control}
              performActions={performCustomerActions}
              key="Customer"
              name="Customer"
              options={["", ...CustomerOptions]}
              filterOptions={(options) => options}
              getOptionLabel={(options) => options}
              renderInput={(params) => {

                
              if(isViewer){
                return (
                  <TextField {...params}
                    InputProps={{...params.inputProps, readOnly: true}}
                    variant={isViewer ? "filled" : "outlined"}
                    InputLabelProps={{ shrink: true }}
                    label="Customer" 
                  />
                )
              }else{
                return (
                  <TextField {...params}
                            variant={isViewer ? "filled" : "outlined"}
                            InputLabelProps={{ shrink: true }}
                            label="Customer" 
                  />
                )
              }

            }}
            />
          </Grid>

          <Grid item xs={6} zeroMinWidth>
            <ControlledAutocompleteV2
              control={control}
              key="SalesPerson"
              name="SalesPerson"
              performActions={performSalesRepActions}
              options={["", ...SalesEmployeeOptions]}
              filterOptions={(options) => options}
              getOptionLabel={(options) => options}
              renderInput={(params) => {
    
                  
                if(isViewer){
                  return (
                    <TextField {...params}
                      InputProps={{...params.inputProps, readOnly: true}}
                      variant={isViewer ? "filled" : "outlined"}
                      InputLabelProps={{ shrink: true }}
                      label="Sales Person" 
                    />
                  )
                }else{
                  return (
                    <TextField {...params}
                              variant={isViewer ? "filled" : "outlined"}
                              InputLabelProps={{ shrink: true }}
                              label="Sales Person" 
                    />
                  )
                }
  
              }}
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledAutocomplete
              control={control}

              name="PriceList"
              options={priceListProps["options"]}
              getOptionLabel={priceListProps["getOptionLabel"]}
              renderInput={(params) => {
    
                  
                if(isViewer){
                  return (
                    <TextField {...params}
                      InputProps={{...params.inputProps, readOnly: true}}
                      variant={isViewer ? "filled" : "outlined"}
                      InputLabelProps={{ shrink: true }}
                      label="Price List" 
                    />
                  )
                }else{
                  return (
                    <TextField {...params}
                              variant={isViewer ? "filled" : "outlined"}
                              InputLabelProps={{ shrink: true }}
                              label="Price List" 
                    />
                  )
                }
  
              }}
            />
          </Grid>

          <Grid item xs={2} zeroMinWidth>
            <ControlledAutocomplete
              control={control}
              name="Currency"
              options={currencyListProps["options"]}
              getOptionLabel={regionProps["getOptionLabel"]}
              renderInput={(params) => {

                
              if(isViewer){
                return (
                  <TextField {...params}
                    InputProps={{...params.inputProps, readOnly: true}}
                    variant={isViewer ? "filled" : "outlined"}
                    InputLabelProps={{ shrink: true }}
                    label="Currency" 
                  />
                )
              }else{
                return (
                  <TextField {...params}
                            variant={isViewer ? "filled" : "outlined"}
                            InputLabelProps={{ shrink: true }}
                            label="Currency" 
                  />
                )
              }

            }}
            />
          </Grid>

          <Grid item xs={3} zeroMinWidth>
            <ControlledAutocomplete
              control={control}
              name="BusinessType"
              options={["", "Spot Buy", "New Business", "Existing Buisness"]}
              filterOptions={(options) => options}
              getOptionLabel={(options) => options}
              renderInput={(params) => {

                
              if(isViewer){
                return (
                  <TextField {...params}
                    InputProps={{...params.inputProps, readOnly: true}}
                    variant={isViewer ? "filled" : "outlined"}
                    InputLabelProps={{ shrink: true }}
                    label="Deal Type" 
                  />
                )
              }else{
                return (
                  <TextField {...params}
                            variant={isViewer ? "filled" : "outlined"}
                            InputLabelProps={{ shrink: true }}
                            label="Deal Type" 
                  />
                )
              }

            }}
            />
          </Grid>

          <Grid item xs={3} zeroMinWidth>
            <ControlledDatePicker
              control={control}
              name="EffectiveDate"
              label="Effective Date"
              renderInput={(params) => {
    
                  
                if(isViewer){
                  return (
                    <TextField {...params}
                      InputProps={{...params.inputProps, readOnly: true}}
                      variant={isViewer ? "filled" : "outlined"}
                      InputLabelProps={{ shrink: true }}
                      label="Effective Date" 
                    />
                  )
                }else{
                  return (
                    <TextField {...params}
                              variant={isViewer ? "filled" : "outlined"}
                              InputLabelProps={{ shrink: true }}
                              label="Effective Date" 
                    />
                  )
                }
  
              }}
            />
          </Grid>

        </Grid>

        {/*Price Info Header */}
        <Grid
          item
          container
          spacing={2}
          xs={12}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} zeroMinWidth>
            <Typography

              variant="h5"
              component="h5"
              pt={1}
              pb={1}
              align="center"
              alignSelf={"center"}
              sx={{
                fontWeight: 500,
                bgcolor: "primary.light",
                color: grey[50],
                mb: 1,
              }}
            >
              Price Request Info
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          pl={2} pr={2}
          spacing={2}
          xs={12}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item xs={6} zeroMinWidth>
            <ControlledAutocompleteV2
              control={control}
              name="SKU"
              options={SkuOptions}
              filterOptions={(options) => options}
              disabled={PriceDataLoading || !watch("Customer")}
              performActions={performSKUActions}
              getOptionLabel={(options) => options}
              renderInput={(params) => {
    
                  
                if(isViewer){
                  return (
                    <TextField {...params}
                      InputProps={{...params.inputProps, readOnly: true}}
                      variant={isViewer ? "filled" : "outlined"}
                      InputLabelProps={{ shrink: true }}
                      label="SKU" 
                    />
                  )
                }else{
                  return (
                    <TextField {...params}
                              variant={isViewer ? "filled" : "outlined"}
                              InputLabelProps={{ shrink: true }}
                              label="SKU" 
                    />
                  )
                }
  
              }}
            />
          </Grid>

          <Grid item xs={6} zeroMinWidth>
            <ControlledAutocompleteV2
              control={control}
              disabled={PriceDataLoading || !watch("Customer")}
              name="Category"
              options={CategoryOptions}
              performActions={performCategoryActions}
              getOptionLabel={(options) => options}
              filterOptions={(options) => options}
              renderInput={(params) => {
    
                  
                if(isViewer){
                  return (
                    <TextField {...params}
                      InputProps={{...params.inputProps, readOnly: true}}
                      variant={isViewer ? "filled" : "outlined"}
                      InputLabelProps={{ shrink: true }}
                      label="Category" 
                    />
                  )
                }else{
                  return (
                    <TextField {...params}
                              variant={isViewer ? "filled" : "outlined"}
                              InputLabelProps={{ shrink: true }}
                              label="Category" 
                    />
                  )
                }
  
              }}
            />
          </Grid>

          <Grid item xs={6} zeroMinWidth>
            <TextField
              key="Description"
              {...register("Description")}
              name="Description"
              color="info"
              focused
              InputLabelProps={{ shrink: true }}
              label="Sku Description"
              fullWidth
              variant="filled"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>


          <Grid item xs={6} zeroMinWidth>
            <TextField
              key="CaseQty"
              {...register("CaseQty")}
              name="CaseQty"
              label="Case Quantity"
              color="info"
              focused
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="filled"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="MonthlyQuantity"
              label="Monthly Quantity (Each)"
              control={control}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              variant={isViewer ? "filled" : "outlined"}
              formatNumber={true}
              performActions={performMonthlyQuantityActions}
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="normal"
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledAutocompleteV2
              name="NumberOfMonths"
              control={control}
              options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]}
              filterOptions={(options) => options}
              getOptionLabel={(options) => options}
              performActions={performNumberOfMonthsActions}
              renderInput={(params) => {
    
                  
                if(isViewer){
                  return (
                    <TextField {...params}
                      InputProps={{...params.inputProps, readOnly: true}}
                      variant={isViewer ? "filled" : "outlined"}
                      InputLabelProps={{ shrink: true }}
                      label="Number Of Months" 
                    />
                  )
                }else{
                  return (
                    <TextField {...params}
                              variant={isViewer ? "filled" : "outlined"}
                              InputLabelProps={{ shrink: true }}
                              label="Number Of Months" 
                    />
                  )
                }
  
              }}
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="TotalQuantity"
              label="Total Quantity (Each)"
              control={control}
              fullWidth
              formatNumber={true}
              variant="filled"
              InputProps={{
                readOnly: true,
              }}
              focused
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="normal"
            />
          </Grid>

          <Grid item xs={12} zeroMinWidth>
            <Typography

              variant="h6"
              component="h6"
              align="center"
              alignSelf={"center"}
              sx={{
                fontWeight: 500,
                bgcolor: blue[300],
                color: grey[50],

              }}
            >
              Original Price Info
            </Typography>
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <TextField
              key="CurrentPrice"
              {...register("CurrentPrice")}
              name="CurrentPrice"
              focused
              InputLabelProps={{ shrink: true }}
              label="Original Price (Each)"
              fullWidth
              variant="filled"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <TextField
              key="CurrentPriceCase"
              {...register("CurrentPriceCase")}
              name="CurrentPriceCase"
              focused
              InputLabelProps={{ shrink: true }}
              label="Original Price (Case)"
              fullWidth
              variant="filled"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="TotalValueCurrent"
              label="Total Value (Original Price)"
              control={control}
              fullWidth
              formatNumber={true}
              variant="filled"
              isCurrency
              InputProps={{
                readOnly: true,
              }}
              focused
              InputLabelProps={{ shrink: true }}
              size="normal"
            />
          </Grid>

          {
            isApprover ?
              (
                <>
                  <Grid item xs={6} zeroMinWidth>
                    <ControlledTextField
                      name="CurrentMargin"
                      label="Original Margin"
                      roundDecimalPercentage={2}
                      control={control}
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      focused
                      InputLabelProps={{ shrink: true }}
                      size="normal"


                    />
                  </Grid>

                  <Grid item xs={6} zeroMinWidth>
                    <ControlledTextField
                      name="StandardCost"
                      label="Standard Cost"
                      roundDecimal={4}
                      control={control}
                      fullWidth
                      hasPrefix="$"
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      focused
                      InputLabelProps={{ shrink: true }}
                      size="normal"

                    />
                  </Grid>
                </>)
              :
              <></>
          }


          <Grid item xs={12} zeroMinWidth>
            <Typography
              variant="h6"
              component="h6"
              align="center"
              alignSelf={"center"}
              sx={{
                fontWeight: 500,
                bgcolor: blue[300],
                color: grey[50],
                mb: 1,
              }}
            >
              Requested Price Info
            </Typography>
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="NewPrice"
              label="New Price (Each)"
              disabled={!watch("Customer") || !watch("SKU")}
              control={control}
              fullWidth
              InputProps={{
                readOnly: isViewer,
              }}
              variant={isViewer ? "filled" : "outlined"}
              InputLabelProps={{ shrink: true }}
              performActions={performNewPriceActions}
              margin="dense"
              size="normal"
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="NewPriceDiscount"
              label="Discount % (Each)"
              disabled={!watch("Customer") || !watch("SKU")}
              control={control}
              fullWidth
              performActions={performNewDiscountActions}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: isViewer,
              }}
              variant={isViewer ? "filled" : "outlined"}
              margin="dense"
              size="normal"
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="NewPriceCase"
              label="New Price (Case)"
              control={control}
              fullWidth
              focused
              variant="filled"
              formatNumber={true}
              isCurrency
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          {/* Add number formatting here */}

          <Grid item xs={6} zeroMinWidth>
            <ControlledTextField
              name="TotalValueNewPrice"
              label="Total Value (New Price)"
              control={control}
              fullWidth
              isCurrency

              formatNumber={true}
              variant="filled"
              InputProps={{
                readOnly: true,
              }}
              focused
              InputLabelProps={{ shrink: true }}
              size="normal"
            />
          </Grid>

          <Grid item xs={6} zeroMinWidth>
            <ControlledTextField
              name="TotalDiscountSku"
              label="Total Discount (Sku)"
              control={control}
              fullWidth
              isCurrency
              formatNumber={true}
              variant="filled"
              InputProps={{
                readOnly: true,
              }}
              focused
              InputLabelProps={{ shrink: true }}
              size="normal"
            />
          </Grid>

          {
            isApprover ?
              (
                <>
                  <Grid item xs={6} zeroMinWidth>
                    <ControlledTextField
                      name="NewMargin"
                      label="New Margin"
                      roundDecimalPercentage={2}
                      control={control}
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      focused
                      InputLabelProps={{ shrink: true }}
                      size="normal"

                    />
                  </Grid>

                  <Grid item xs={6} zeroMinWidth>
                    <ControlledTextField
                      name="CustomerCost"
                      label="Customer Cost"
                      roundDecimal={4}
                      control={control}
                      fullWidth
                      hasPrefix="$"
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      focused
                      InputLabelProps={{ shrink: true }}
                      size="normal"
                    />
                  </Grid>
                </>)
              :
              <></>
          }

          {
            isCurrentApprover ?
              (<Grid item xs={12} zeroMinWidth>
                <TextField
                  name="Remarks"
                  fullWidth
                  multiline
                  disabled={!watch("Customer")}
                  rows={3}
                  id="Remarks"
                  label="Remarks"
                  shrink
                  InputProps={{
                    readOnly: false,
                  }}
                  variant={"outlined"}
                  defaultValue={"0.23"}
                  {...register("Remarks")}
                  size="normal"
                />
              </Grid>)
              :
              (<Grid item xs={12} zeroMinWidth>
                <TextField
                  name="Remarks"
                  fullWidth
                  multiline
                  disabled={!watch("Customer")}
                  rows={3}
                  id="Remarks"
                  shrink
                  label="Remarks"
                  InputProps={{
                    readOnly: isViewer,
                  }}
                  variant={isViewer ? "filled" : "outlined"}
                  defaultValue={"0.23"}
                  {...register("Remarks")}
                  size="normal"
                />
              </Grid>)
          }

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="Approver"
              label="Approver"
              control={control}
              fullWidth
              focused
              variant="filled"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={6} zeroMinWidth>
            <ControlledAutocomplete
              control={control}
              name="Justification"
              disabled={!watch("Customer")}
              options={justificationProps["options"]}
              getOptionLabel={justificationProps["getOptionLabel"]}
              renderInput={(params) => {
    
                  
                if(isViewer){
                  return (
                    <TextField {...params}
                      InputProps={{...params.inputProps, readOnly: true}}
                      variant={isViewer ? "filled" : "outlined"}
                      InputLabelProps={{ shrink: true }}
                      label="Justification" 
                    />
                  )
                }else{
                  return (
                    <TextField {...params}
                              variant={isViewer ? "filled" : "outlined"}
                              InputLabelProps={{ shrink: true }}
                              label="Justification" 
                    />
                  )
                }
  
              }}
            />
          </Grid>

          {
            isCurrentApprover ?
              (
                <Grid item xs={2} zeroMinWidth>
                <Button
                  type="submit"
                  fullWidth
                  disabled={getValues("SKU") == "" || getValues("SKU") == undefined || PriceDataLoading || !watch("Customer")}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onAddRow)}
                >
                  {editingRow ? BlockUseEffect ? "Loading" : "Add Remark" : "Add"}
                </Button>
              </Grid>
              )
              :
              (
              isViewer ? (
                <></>
              ) : (
                <Grid item xs={2} zeroMinWidth>
                <Button
                  type="submit"
                  fullWidth
                  disabled={getValues("SKU") == "" || getValues("SKU") == undefined || PriceDataLoading || !watch("Customer")}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onAddRow)}
                >
                  {editingRow ? BlockUseEffect ? "Loading" : "Add Remark" : "Add"}
                </Button>
              </Grid>
              )
              )
          }

        </Grid>

        <Grid
          item
          pl={2} pr={2}
          container
          spacing={2}
          sx={{
            xs: 12,
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Grid height={500} item xs={12} zeroMinWidth >
            <DataGridPro
              height={600}
              editMode="row"
              sx={{
                "& .MuiDataGrid-cell": {
                  borderRight: `2px solid ${"#d4cfcf"
                    }`
                },

                '& .MuiDataGrid-columnHeaderTitle': {
                  textOverflow: "clip",
                  whiteSpace: "break-spaces",
                  lineHeight: 1
                }
              }}
              pinnedColumns={{ "left": ["actions", "SKU"] }}
              rows={[...rows]}
              columns={PriceDatagridColumns}
              apiRef={apiRef}
            />
          </Grid>

          <Grid item xs={12} zeroMinWidth>
            <Typography
              variant="h6"
              component="h6"
              align="center"
              alignSelf={"center"}
              sx={{
                fontWeight: 500,
                bgcolor: blue[300],
                color: grey[50],
                mb: 1,
              }}
            >
              Request Deal Totals
            </Typography>
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="TotalDealValue"
              label="Total Deal Value ($)"
              control={control}
              fullWidth
              isCurrency
              focused
              variant="filled"
              formatNumber={true}
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="TotalDiscountValue"
              label="Total Discount Value ($)"
              control={control}
              fullWidth
              isCurrency
              variant="filled"
              formatNumber={true}
              focused
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={4} zeroMinWidth>
            <ControlledTextField
              name="TotalDiscountPercentage"
              label="Total Discount Value(%)"
              control={control}
              fullWidth
              variant="filled"
              focused
              percentage={true}
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>





          {isCurrentApprover ?
            <>
              <Grid pb={2} item xs={2} zeroMinWidth>
                <Button
                  type="submit"
                  fullWidth
                  disabled={BlockSubmit}
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit(onSaveDraft)}
                >
                  Save Draft
                </Button>
              </Grid>

              <Grid pb={2} xs={2} item zeroMinWidth>
                <Button
                  type="submit"
                  disabled={BlockSubmit}
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={handleSubmit(onAccept)}
                >
                  Approve
                </Button>
              </Grid>

              <Grid pb={2} item xs={2} zeroMinWidth>
                <Button
                  type="submit"
                  fullWidth
                  disabled={BlockSubmit}
                  variant="contained"
                  color="info"
                  onClick={handleSubmit(onSendBack)}
                >
                  Send Back
                </Button>
              </Grid>

              <Grid pb={2} item xs={2} zeroMinWidth>
                <Button
                  type="submit"
                  fullWidth
                  disabled={BlockSubmit}
                  variant="contained"
                  color="error"
                  onClick={handleSubmit(onDecline)}
                >
                  Decline
                </Button>
              </Grid>
            </>
            :
            <></>
          }




          {
            RequestStatus.includes("Drafting") ? (
              <>
                <Grid pb={2} item xs={2} zeroMinWidth>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={BlockSubmit}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid pb={2} item xs={2} zeroMinWidth>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={BlockSubmit}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSaveDraft)}
                  >
                    Save Draft
                  </Button>

                </Grid>
              </>
            ) :
              (
                <>
                </>
              )
          }

        <Grid item xs={12} zeroMinWidth/>


        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}



// Pull out pricelist - product lines \\\


// Set standard cost as customer zone