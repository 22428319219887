import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import React from "react";
import NumberFormat from "react-number-format";
import { CatchingPokemonSharp } from "@mui/icons-material";


export const ControlledTextField = ({ name, control, label, percentage,hasPrefix = "", roundDecimal,roundDecimalPercentage, formatNumber, isCurrency=false, performActions=()=>{}, ...props }) => {
  if (percentage === true) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField label={label} variant={props?.variant} sx={props?.sx} shrink={props?.shrink} fullWidth={props?.fullWidth} disabled={props?.disabled} InputLabelProps={props?.InputLabelProps} InputProps={props?.InputProps} focused={props?.focused} size={props?.size} readOnly={props?.readOnly} color={props?.color} {...field}
            value={`${Number(field.value).toLocaleString()}%`} onChange={(e) => { field.onChange(e); performActions(e.target.value); }} />
        )
        }
      />
    );
  } else if (roundDecimal) {
    return (<Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField label={label} variant={props?.variant} sx={props?.sx} shrink={props?.shrink} fullWidth={props?.fullWidth} disabled={props?.disabled} InputLabelProps={props?.InputLabelProps} InputProps={props?.InputProps} focused={props?.focused} size={props?.size} readOnly={props?.readOnly} color={props?.color} {...field}
          value={`${hasPrefix}${String(parseFloat(field.value).toFixed(roundDecimal))}`} onChange={(e) => { field.onChange(e); performActions(e.target.value); }} />
      )
      }
    />);
    
  }else if(roundDecimalPercentage){
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField label={label} variant={props?.variant} sx={props?.sx} shrink={props?.shrink} fullWidth={props?.fullWidth} disabled={props?.disabled} InputLabelProps={props?.InputLabelProps} InputProps={props?.InputProps} focused={props?.focused} size={props?.size} readOnly={props?.readOnly} color={props?.color} {...field}
            value={
              
              `${hasPrefix}${Number( (parseFloat(field.value).toFixed(roundDecimalPercentage) * 100)).toLocaleString()} %`} onChange={(e) => { field.onChange(e); performActions(e.target.value); }} />
        )
        }
      />
    );
  }else if (formatNumber) {
    return (
    <Controller
    name={name}
    control={control}
      render={({ field: { onChange, onBlur, name, value, ref } }) => (
        <NumberFormat
          label={label} 
          variant={props?.variant} 
          sx={props?.sx} 
          shrink={props?.shrink} 
          fullWidth={props?.fullWidth} 
          disabled={props?.disabled} 
          InputLabelProps={props?.InputLabelProps} 
          InputProps={props?.InputProps} 
          focused={props?.focused} 
          size={props?.size} 
          prefix={isCurrency ? "$" : ""}
          readOnly={props?.readOnly} 
          color={props?.color}
          customInput={TextField}
          thousandSeparator
          onValueChange={(c) => {
            onChange(c.floatValue);
            performActions(c.value);
          }}

          name={name}
          value={value}
          onBlur={onBlur}
          ref={ref}

        />)}

    />);

  } else {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField label={label} variant={props?.variant} sx={props?.sx} shrink={props?.shrink} fullWidth={props?.fullWidth} disabled={props?.disabled} InputLabelProps={props?.InputLabelProps}
            InputProps={props?.InputProps} focused={props?.focused} size={props?.size} readOnly={props?.readOnly} color={props?.color} {...field}
            onChange={(e) => { field.onChange(e); performActions(e.target.value); }} />
        )
        }
      />
    );
  }

};