import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthenticatedTemplate } from "@azure/msal-react";

import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import HomeIcon from "@mui/icons-material/Home";
import EventNoteIcon from "@mui/icons-material/EventNote";

import NavBar from "../navbar/Navbar";


import { Divider } from "@mui/material";
import { Link } from "./../../utils/router";

import { useMsal, useIsAuthenticated  } from "@azure/msal-react";
import { useRouter } from "./../../utils/router";
import { useWarmUp } from "./../../utils/db";



const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flex: 1 | 1 | "90%" ,  
    display: "flex",
    justifyContent:"center",
    alignItems:"center",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

export default function PageLayout(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const warmUpFunctions = useWarmUp(instance, accounts);


  const handleDrawerClick = () => {
    setOpen(!open);
  };

  function hasAccess() {
    const a = 1;
    const account = accounts[0];

    // const roles = account.idTokenClaims?.roles
    //   ?.filter((x) => x.includes("user.companycode"))
    //   .map((y) => y.replace("user.companycode.", "").toUpperCase());
    
    // const HrMonthlyAppPermissions = (roles?.length >= 1 ? true : false);
    // if(HrMonthlyAppPermissions && isAuthenticated){
    //   return (
    //     <ListItem button key={"Monthly Report"} component={Link} to={"/MonthlyReport"}>
    //     <ListItemIcon>
    //     <EventNoteIcon />
    //     </ListItemIcon>
    //     <ListItemText primary={"Monthly Report"} />
    //     </ListItem>
    //   )
    // }else{
    //   return <></>
    // }

    return (
      <React.Fragment>
        <ListItem button key={"AllRequests"} component={Link} to={"/AllRequests"}>
        <ListItemIcon>
          <FormatListNumberedIcon />
        </ListItemIcon>
        <ListItemText primary={"All Requests"} />
        </ListItem>
      </React.Fragment>
    )

  
  }

  const router = useRouter();

  return (
    <Box
      id={"MainFlexContainer-"}
      height={1}
      width={1}
      sx={{ display: "flex", flexDirection: "column", alignItems:"normal", alignContent:"normal", justifyContent: "normal" }}
      overflow={"auto"}
    >
      <CssBaseline />
      <NavBar order={0} open={open} handleDrawerClick={handleDrawerClick} sx={{ flexGrow: 0, flexShrink: 1, flexBasis: "auto", alignSelf: "auto", order:0}} />

      <Box order={1} id={"body-container-"} flex={1} alignSelf={"stretch"} >

        <Box id={"test"} height={1} sx={{ display: "flex" }}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexGrow: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                height: 1,
                flexGrow: 0,
                position: "static",
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <Typography
              variant="h6"
              fontWeight={600}
              component="div"
              alignSelf={"center"}
              sx={{ flex: 0, mt:2 }}
            >
              Menu
            </Typography>
            <Divider variant="middle" />


            <List>


              <ListItem button key={"Home"} component={Link} to={"/"}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItem>



              <AuthenticatedTemplate>
                {
                  hasAccess
                }
              </AuthenticatedTemplate>


            </List>
          </Drawer>

          <Main open={open} >
            {!props.children && (
              <CircularProgress
                component="div"
                size={32}
                alignSelf={"stretch"}
                justifySelf={"stretch"}
              />
            )}
            {props.children && <>{props.children}</>}
          </Main>
        </Box>
      </Box>

    </Box>
  );
}
