import { MsalProvider } from "@azure/msal-react";
import { QueryClientProvider } from "./../utils/db";
import { Switch, Route, Router } from "./../utils/router";
import { ReactQueryDevtools } from 'react-query/devtools'

// Components
import PageLayout from "../components/pagelayout/PageLayout";

// Pages
import Home from "./../pages/home/Home";
import CreateApprovalRequestPage from "./approval_request/create/CreateApprovalRequestPage";
import ViewApprovalListPage from "./approval_list/ViewApprovalListPage";
import UpdatePriceApprovalPage from "./approval_request/update/updateApprovalRequestPage";

function App(props) {
  return (
    <MsalProvider instance={props.instance}>
      <QueryClientProvider>
        <Router>
          <PageLayout height={1} >
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/request/create/:requestType" component={CreateApprovalRequestPage} />
              <Route exact path="/request/update/:requestType/:requestID" component={UpdatePriceApprovalPage} />
              <Route exact path="/AllRequests"  component={ViewApprovalListPage} />
              <Route exact path="/MyRequests"  component={ViewApprovalListPage} />
              <Route component={<div>Not Found</div>} />
            </Switch>
          </PageLayout>
        </Router>
        <ReactQueryDevtools initialIsOpen={true} /> 
      </QueryClientProvider>
    </MsalProvider>
  );
}

export default App;
