import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/_app";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authconfig";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "fb7b85b07af5c818ed57784d71cd9d54T1JERVI6MzIzMjQsRVhQSVJZPTE2NjgxMTM2NzkwMDAsS0VZVkVSU0lPTj0x"
);

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <App instance={msalInstance} />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
