import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authconfig";
import { useIsAuthenticated } from "@azure/msal-react";

import { useRouter } from "../../utils/router";
export default function NavBar(props) {
  const [menuState, setMenuState] = useState(null);
  const { instance } = useMsal();
  const router = useRouter();
  const auth = useIsAuthenticated();

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  console.log(router.pathname.split("/"))
  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={props.handleDrawerClick}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            { router.pathname !== "/request/new/Price_Approval" ? "Home" : "New Request" }
          </Typography>
          {!auth && (
            <Button
              onClick={() => {
                instance.loginPopup(loginRequest).catch((e) => {
                  console.log(e);
                });
              }}
              color="inherit"
            >
              Sign in
            </Button>
          )}

          {auth && (
            <Button
              onClick={(event) => {
                instance.logoutPopup({
                  postLogoutRedirectUri: "/",
                  mainWindowRedirectUri: "/",
                });
              }}
              color="inherit"
            >
              Sign Out
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
