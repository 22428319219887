import * as React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import Card from '@mui/material/Card';
import { CardContent } from "@mui/material";
import { CardHeader } from "@mui/material";
import { grey } from '@mui/material/colors';
import { useRouter } from '../../utils/router';

export default function Home() {
  const [newRequest, setNewRequest] = React.useState("");

  const router = useRouter();

  const handleChange = (event) => {
    setNewRequest(event.target.value);
  };

  return (
    <>
      <AuthenticatedTemplate>
        <Card sx={{ minWidth: 400, minHeight: 300, boxShadow:2 }}>
        <CardHeader title="New Approval Request" sx={{textAlign: "center", bgcolor: "info.main", color: grey[50] }}/>

        <CardContent>
          <Box sx={{ width: 1, height: 1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems: 'center', alignContent: 'center', mt: 2}} >
              <FormControl sx={{ width: 10/12, mb:2}}>
                <InputLabel id="newRequestDropDownLB">Select A New Request Type</InputLabel>
                <Select
                  labelId="newRequestDropDownLB"
                  id="newRequestDropDown"
                  value={newRequest}
                  label="Select A New Request Type"
                  onChange={handleChange}
                >
                  <MenuItem value={""}>         </MenuItem>
                  <MenuItem value={"Price_Approval"}>Price Approval</MenuItem>
                  {/* <MenuItem value={"New_HeadCount"}>New HeadCount</MenuItem>
                  <MenuItem value={"Employee_Change"}>Employee Change</MenuItem> */}
                </Select>
              </FormControl>
              <Button disabled={newRequest !== "" ? false : true} variant="contained" sx={{ alignSelf:"flex-end", mr:3}}  onClick={(e) => router.push('/request/create/' + newRequest)} >Create</Button>
          </Box>
          </CardContent>
        </Card>


      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Typography
          variant="h3"
          component="h3"
          flexGrow={2}
          align="center"
          alignSelf={"center"}
          sx={{ fontWeight: 600 }}
        >
          You are NOT signed in.
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
}
