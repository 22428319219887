import { Autocomplete, TextField } from "@mui/material";
import { Controller } from 'react-hook-form';

export const ControlledAutocompleteV2 = ({ options = [], disabled = false, renderInput, getOptionLabel, onChange: ignored, control, defaultValue, name, renderOption, performActions = (data)=>{} }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          onChange={(event, item) => {
            onChange(item);
            performActions(item);
          }}
          value={value}
          disabled={disabled}
          options={options}
          getOptionLabel={getOptionLabel}
          renderInput={renderInput}
        />
      )}

      onChange={([, data]) => data}
      defaultValue={defaultValue}
    />
  );
}