import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

async function acquireMsalToken(instance, accounts, scopes = ["user.read"]) {
  if (!instance) {
    return null;
  }

  const currentAccounts = instance.getAllAccounts();

  let user;

  if (!currentAccounts || currentAccounts.length === 0) {
    // No user signed in
    user = null;
  } else if (currentAccounts.length > 1) {
    user = currentAccounts[0];
  } else {
    user = currentAccounts[0];
  }

  // Set scopes for token request
  const accessTokenRequest = {
    scopes,
    account: user,
  };

  let tokenResp;

  try {
    // 1. Try to acquire token silently
    tokenResp = await instance.acquireTokenSilent(accessTokenRequest);
    console.log("### MSAL acquireTokenSilent was successful");
  } catch (err) {
    // 2. Silent process might have failed so try via popup
    tokenResp = await instance.acquireTokenPopup(accessTokenRequest);
    console.log("### MSAL acquireTokenPopup was successful");
  }

  // Just in case check, probably never triggers
  if (!tokenResp.accessToken) {
    throw new Error("### accessToken not found in response, that's bad");
  }

  return tokenResp.accessToken;
}

export async function apiRequest(
  instance,
  accounts,
  scopes,
  path,
  method = "GET",
  data
) {
  const accessToken = await acquireMsalToken(instance, accounts, scopes);

  // const currentAccounts = instance.getAllAccounts();
  // console.log(
  //   "IdToken: ",
  //   currentAccounts[0].idTokenClaims,
  //   " AccessToken: ",
  //   accessToken
  // );

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "X-Custom-Access-Token": `${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      if (response.status === "error") {
        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}
